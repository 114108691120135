import React, { type FunctionComponent } from 'react';
import { useIntl } from 'react-intl-next';

import { messages } from '../messages';
import AdvancedSearchFooter, {
	type AdvancedSearchFooterProps,
} from '../../../advanced-search-footer';

import { SearchFooterWrapper, FeedbackLinkWrapper, FeedbackLink } from './styled';

export interface WithTrelloFooterProps extends AdvancedSearchFooterProps {
	feedbackUrl?: string;
}

export const TrelloFooter: FunctionComponent<WithTrelloFooterProps> = ({
	feedbackUrl,
	...rest
}) => {
	const { formatMessage } = useIntl();
	return (
		<SearchFooterWrapper>
			<AdvancedSearchFooter {...rest} />
			<FeedbackLinkWrapper>
				<FeedbackLink appearance="subtle-link" href={feedbackUrl} target="_blank">
					{formatMessage(messages.trello_feedback_link)}
				</FeedbackLink>
			</FeedbackLinkWrapper>
		</SearchFooterWrapper>
	);
};
