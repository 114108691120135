import {
	type ActivityItemEdge,
	is1PActivity,
	isDocumentActivity,
	isGraphActivity,
	recentActivitiesQuery,
} from '@atlassian/search-client';

export const fetchRecentActivities = async (
	cloudId: string,
	aggAbsoluteUrl: string | undefined,
) => {
	return await recentActivitiesQuery({
		variables: {
			filteredProducts: ['confluence', 'jira', 'townsquare'],
			filteredObjectTypes: [
				'page',
				'blogpost',
				'whiteboard',
				'issue',
				'project',
				'goal',
				'content',
			],
			filteredRootContainerIds: [
				`ari:cloud:platform::site/${cloudId}`,
				`ari:cloud:townsquare::site/${cloudId}`,
			],
			itemsLimit: 200,
		},
		aggAbsoluteUrl,
	}).then((response) => {
		const edges = response.data?.activity?.myActivity?.all?.edges || [];
		return edges.map(mapEssentialData);
	});
};

export const mapEssentialData = (result: ActivityItemEdge) => {
	const product = result.node.object.product;
	let title = '';

	if (is1PActivity(result.node.object)) {
		if (product === 'confluence') {
			title = result.node.object.data?.title || '';
		} else if (product === 'jira') {
			const key = result.node.object.data?.key;
			const summary = result.node.object.data?.fieldsById?.edges?.[0]?.node?.text;
			title = key && summary ? `${key}: ${summary}` : '';
		} else if (product === 'townsquare') {
			title = result.node.object.data?.name || '';
		}
	} else if (isGraphActivity(result.node.object)) {
		if (isDocumentActivity(result.node.object)) {
			title = result.node.object.data?.displayName || '';
		}
	}

	return {
		id: result.node.object.id,
		title,
		data: result,
		type: 'recent-activity' as const,
	};
};
