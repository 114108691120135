import React, { useMemo } from 'react';

import CustomizeIcon from '@atlaskit/icon/core/customize';
import { Box, xcss } from '@atlaskit/primitives';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { ProductKeys } from '../../../../common/constants/products';
import useLocalStorage from '../../../../controllers/store/external-storage/use-local-storage';

import { HeaderProductButtonFinal } from './header-product-button';

const iconAreaStyles = xcss({
	width: 'size.200',
	height: 'size.200',
	display: 'flex',
	flexShrink: '0',
	alignItems: 'center',
	justifyContent: 'center',
});

const productFilterHeaderContainerStyle = xcss({
	paddingBottom: 'space.075',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
});

const productFilterHeaderStyle = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	gap: 'space.100',
	overflowX: 'hidden',
	marginLeft: 'space.150',
});

export const ProductFilterHeader = ({
	availableProducts,
}: {
	availableProducts: ProductKeys[];
}) => {
	const [storedState] = useLocalStorage();

	const hiddenProducts = useMemo(() => {
		if (!storedState || !storedState.filters) {
			return [];
		}

		return Object.keys(storedState.filters)
			.filter((product) => storedState.filters[product].hidden)
			.map((product) => product as ProductKeys);
	}, [storedState]);

	const combinedProducts: ProductKeys[] = useMemo(() => {
		const products1P: ProductKeys[] = [ProductKeys.Confluence, ProductKeys.Jira]
			.filter((product) => availableProducts.includes(product))
			.filter((product) => !hiddenProducts.includes(product));

		const products3P: ProductKeys[] = [
			ProductKeys.GoogleDrive,
			ProductKeys.Sharepoint,
			ProductKeys.Teams,
			ProductKeys.Slack,
		]
			.filter((product) => availableProducts.includes(product))
			.filter((product) => !hiddenProducts.includes(product))
			.slice(0, 2);

		return [...products1P, ...products3P];
	}, [availableProducts, hiddenProducts]);

	if (combinedProducts.length === 0) {
		return null;
	}

	return (
		<Box xcss={productFilterHeaderContainerStyle} id="product-filter-header">
			<Box xcss={iconAreaStyles}>
				<CustomizeIcon spacing="none" label={''} color={token('color.icon', N500)} />
			</Box>
			<Box xcss={productFilterHeaderStyle}>
				{combinedProducts.map((product) => (
					<HeaderProductButtonFinal key={product} otherProps={{}} productKey={product} />
				))}
			</Box>
		</Box>
	);
};
