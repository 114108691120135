import React, { Fragment } from 'react';
import { css } from '@compiled/react';

import Image from '@atlaskit/image';
import Avatar from '@atlaskit/avatar';
import PageIcon from '@atlaskit/icon-object/glyph/page/16';
import PageIcon24 from '@atlaskit/icon-object/glyph/page/24';
import BlogIcon from '@atlaskit/icon-object/glyph/blog/16';
import BlogIcon24 from '@atlaskit/icon-object/glyph/blog/24';
import GenericIcon from '@atlaskit/icon-file-type/glyph/generic/16';
import GenericIcon24 from '@atlaskit/icon-file-type/glyph/generic/24';
import Issue24Icon from '@atlaskit/icon-object/glyph/issue/24';
import { token } from '@atlaskit/tokens';

import { AttachmentIcon } from '@confluence/icons/entry-points/AttachmentIcon';
import { LegacyContentIcon } from '@confluence/icons/entry-points/LegacyContentIcon';

const NegativeMargin = css({
	margin: token('space.negative.025', '-2px'),
	display: 'flex',
});

type ContentIconRendererArgs = {
	type: string;
	title?: string;
	iconCssClass?: string;
	size?: 'medium' | 'small';
	imageSrc?: string;
};

export const ContentIconRenderer = ({
	type,
	title,
	iconCssClass,
	size = 'medium',
	imageSrc,
}: ContentIconRendererArgs) => {
	if (type === 'page' || type === 'confluence-page' || type === 'content') {
		const Icon = size === 'medium' ? PageIcon24 : PageIcon;
		return <Icon label={type} />;
	} else if (type === 'blogpost' || type === 'confluence-blogpost') {
		const Icon = size === 'medium' ? BlogIcon24 : BlogIcon;
		return <Icon label={type} />;
	} else if (type === 'whiteboard') {
		// CTIA-142 - This will be swapped out when AK supports the whiteboard icon
		return <LegacyContentIcon size={size} type="whiteboard" label={type} />;
	} else if (type === 'database') {
		// CTIA-142 - This will be swapped out when AK supports the database icon
		return <LegacyContentIcon size={size} type="database" label={type} />;
	} else if (type === 'embed') {
		// CTIA-142 - This will be swapped out when AK supports the embed icon
		return <LegacyContentIcon size={size} type="embed" label={type} />;
	} else if (type === 'folder') {
		return <LegacyContentIcon size={size} type="folder" label={type} />;
	} else if (type === 'jira') {
		return <Issue24Icon label={type} />;
	} else if (type === 'atlas') {
		return <Image src={imageSrc || ''} aria-label={type} alt={type} width="24px" height="24px" />;
	} else if (type === 'attachment') {
		if (!iconCssClass) {
			const Icon = size === 'medium' ? GenericIcon24 : GenericIcon;
			return <Icon label={type} />;
		} else {
			return <AttachmentIcon mediaType={iconCssClass} title={title} size={size} label={type} />;
		}
	}
	return <Fragment />;
};

type GenericIconRendererArgs = {
	iconUrl: string;
	size?: 'small' | 'xsmall';
	appearance?: 'square' | 'circle';
};

export const GenericIconRenderer = ({
	iconUrl,
	size = 'small',
	appearance = 'square',
}: GenericIconRendererArgs) => (
	<div css={NegativeMargin}>
		<Avatar
			size={size}
			borderColor="transparent"
			src={iconUrl}
			appearance={appearance}
			testId="result-avatar"
		/>
	</div>
);
