import {
	ConfluenceGraphQLDateFilterFieldType,
	type ConfluenceGraphQLFilterVariablesType,
	type ConfluenceGraphQLSearchVariables,
	type ConfluenceGraphQLSearchVariablesMapper,
} from './types';

const CONFLUENCE_ARI_PREFIX = 'ari:cloud:confluence::site/';
const MAX_RESULTS_PAGE_BLOG_ATTACHMENTS = 10;
const MAX_RESULTS_SPACES = 3;
export const DEFAULT_CONFLUENCE_ENTITIES = [
	'ati:cloud:confluence:page',
	'ati:cloud:confluence:blogpost',
	'ati:cloud:confluence:attachment',
];

const graphQLFilterMapper = (filters: any[]) => {
	const confluenceFilters: ConfluenceGraphQLFilterVariablesType = {};

	filters.forEach((filter) => {
		switch (filter['@type']) {
			case 'spaces':
				confluenceFilters['spacesFilter'] = filter.spaceKeys;
				break;
			case 'verified':
				confluenceFilters['isVerified'] = filter.verified;
				break;
			case 'contributors':
				confluenceFilters['contributorsFilter'] = filter.accountIds;
				break;
			case 'lastModified':
				confluenceFilters['range'] = {
					field: ConfluenceGraphQLDateFilterFieldType.LASTMODIFIED,
					gt: filter.from,
					lt: filter.to,
				};
				break;
			case 'titleMatchOnly':
				confluenceFilters['titleMatchOnly'] = filter.enabled;
				break;
			default:
				break;
		}
	});

	return confluenceFilters;
};

export const hasCheckedFilters = (filters: any[]) =>
	filters.some((filter) => {
		if (filter['@type'] === 'spaces' && filter.spaceKeys && filter.spaceKeys.length > 0) {
			return true;
		} else if (
			filter['@type'] === 'contributors' &&
			filter.accountIds &&
			filter.accountIds.length > 0
		) {
			return true;
		} else if (filter['@type'] === 'lastModified' && filter.from && filter.to) {
			return true;
		}
		return false;
	});

export const confluenceGraphQLSearchVariablesMapper = ({
	query,
	filters,
	contentEntities = DEFAULT_CONFLUENCE_ENTITIES,
	analyticsInfo,
	cloudId,
	pageBlogAttachmentLimit = MAX_RESULTS_PAGE_BLOG_ATTACHMENTS,
	spaceLimit = MAX_RESULTS_SPACES,
	includeAncestors = false,
	experimentId,
	shadowExperimentId,
	experimentLayers,
}: ConfluenceGraphQLSearchVariablesMapper): ConfluenceGraphQLSearchVariables => {
	return {
		query,
		cloudIdARI: `${CONFLUENCE_ARI_PREFIX}${cloudId}`,
		pageBlogAttachmentLimit,
		spaceLimit,
		includeAncestors,
		contentEntities,
		confluenceFilters: graphQLFilterMapper(filters),
		searchSessionId: analyticsInfo?.searchSessionId,
		queryVersion: analyticsInfo?.queryVersion,
		includeSpaces: !hasCheckedFilters(filters),
		experimentContext: {
			experimentId,
			shadowExperimentId,
			experimentLayers,
		},
	};
};
