import React, { type ReactNode, useCallback } from 'react';

import { FormattedMessage } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import {
	type ActivityItemEdge,
	type ActivityNode,
	type ExternalDocument,
	isDocumentActivity,
	isGraphActivity,
	type JiraStatusCategoryColorType,
	type QuickFind1PEntity,
	type QuickFindGraphEntity,
	SearchConfluenceSubtype,
} from '@atlassian/search-client';
import {
	getGenericUrlWithAnalyticsQueryParams,
	highlightMatchedText,
} from '@atlassian/search-common';

import { ReturnIcon } from '../../../../common/constants/icons/return';
import { ProductKeys } from '../../../../common/constants/products';
import { EventTypeMessage } from '../../../../common/ui/event-type-message';
import { ProductIcon } from '../../../../common/ui/product-icon';
import { getDocumentSubtypeLabel } from '../../../../common/ui/quick-find/3p-result-labeling';
import { ContentIcon } from '../../../../common/ui/quick-find/content-icon';
import { getStatus } from '../../../../common/ui/quick-find/jira-status';
import {
	SearchResult,
	type SearchResultEventArguments,
} from '../../../../common/ui/quick-find/search-dialog-result';
import { type SearchMetadataPart } from '../../../../common/ui/search-metadata';
import { type State } from '../../../../common/utils/quick-find/state';
import { type QuickFindResultEvent } from '../../../../controllers/quick-find/utils';
import { useIsVisualRefreshEnabled, useSearchSessionId } from '../../../../controllers/store';
import { useQuickFindQuery } from '../../../../controllers/store/quick-find';

import { messages } from './messages';

const typeLabelContainer = xcss({
	'::first-letter': {
		textTransform: 'capitalize',
	},
});

const mapConfluenceActivityToSearchResult = (
	activity: ActivityNode<QuickFind1PEntity>,
	query: string,
	showVisualRefresh: boolean,
) => {
	if (!activity.object.data) {
		return;
	}
	const id = activity.object.id;
	const { title, space, links } = activity.object.data;

	const icon = showVisualRefresh ? (
		<ProductIcon size="small" product={ProductKeys.Confluence} />
	) : (
		<ContentIcon type={activity.object.type} title={title} />
	);
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const isLiveDoc =
		activity.object.data?.subtype === SearchConfluenceSubtype.LIVE &&
		fg('confluence_live_pages_ab_test_opted_in');

	const type = [
		'confluence-type',
		<Box xcss={typeLabelContainer}>
			{isLiveDoc ? <FormattedMessage {...messages.liveDoc} /> : activity.object.type}
		</Box>,
	] satisfies SearchMetadataPart;
	const spaceName = space?.name || '';
	const metadata = [type, spaceName] satisfies SearchMetadataPart[];

	const timestamp = activity.event.timestamp;
	const eventType = activity.event.eventType;
	const rightMetadata = (
		<EventTypeMessage timestamp={timestamp} eventType={eventType} isMyActivityData={true} />
	);
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const urlBase = links?.base;
	const urlWebUi = links?.webUi;
	const url = urlBase && urlWebUi && `${urlBase}${urlWebUi}`;

	const spaceId = space?.id;
	const analyticsMetadata = {
		contentId: id,
		containerId: spaceId,
		contentType: `confluence ${activity.object.type}`,
	};

	if (!title || !url) {
		return undefined;
	}

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

const mapJiraActivityToSearchResult = (
	activity: ActivityNode<QuickFind1PEntity>,
	query: string,
	showVisualRefresh: boolean,
) => {
	if (!activity.object.data) {
		return;
	}
	const id = activity.object.id;
	const { title: originalTitle, key, fieldsById, webUrl } = activity.object.data;
	const statusName = activity.object.data?.statusCategory?.name;
	const statusColor = activity.object.data?.statusCategory
		?.colorName as JiraStatusCategoryColorType;
	const status = getStatus(statusName, statusColor);

	const icon = showVisualRefresh ? (
		<ProductIcon size="small" product={ProductKeys.Jira} />
	) : (
		<ContentIcon type="jira" title={originalTitle} />
	);

	const summary = fieldsById?.edges?.[0]?.node?.text;
	const title = key && summary && `${key}: ${summary}`;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const titleMetadataParts: [string, ReactNode][] =
		status && fg('enable_status_jira_issues_quick_find') ? [['status', status]] : [];

	const type = <Box xcss={typeLabelContainer}>{activity.object.type}</Box>;
	const metadata = [['jira-type', type]] satisfies SearchMetadataPart[];

	const timestamp = activity.event.timestamp;
	const eventType = activity.event.eventType;
	const rightMetadata = (
		<EventTypeMessage timestamp={timestamp} eventType={eventType} isMyActivityData={true} />
	);
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const analyticsMetadata = {
		contentId: id,
		contentType: `jira ${activity.object.type}`,
	};

	if (!title || !webUrl) {
		return undefined;
	}

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url: webUrl,
		analyticsMetadata,
		titleMetadataParts,
	};
};

const mapTownsquareActivityToSearchResult = (
	activity: ActivityNode<QuickFind1PEntity>,
	query: string,
	showVisualRefresh: boolean,
) => {
	if (!activity.object.data) {
		return;
	}
	const id = activity.object.id;
	const { iconData, title, name, key, url } = activity.object.data;

	const icon = showVisualRefresh ? (
		<ProductIcon size="small" product={ProductKeys.Atlas} />
	) : (
		<ContentIcon type="atlas" imageSrc={iconData || ''} title={title} />
	);

	const highlightedTitle =
		name && query ? highlightMatchedText(name, query.split(' ')).highlightedTitle : undefined;

	const type = [
		'type',
		<Box xcss={typeLabelContainer}>{activity.object.type}</Box>,
	] satisfies SearchMetadataPart;
	const metadata = [type, key ?? null] satisfies SearchMetadataPart[];

	const timestamp = activity.event.timestamp;
	const eventType = activity.event.eventType;
	const rightMetadata = (
		<EventTypeMessage timestamp={timestamp} eventType={eventType} isMyActivityData={true} />
	);
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const analyticsMetadata = {
		contentId: id,
		contentType: `townsquare ${activity.object.type}`,
	};

	if (!name || !url) {
		return undefined;
	}

	return {
		id,
		icon,
		title: name,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

const mapGraphDocumentActivityToSearchResult = (
	activity: ActivityNode<ExternalDocument>,
	query: string,
) => {
	if (!activity.object.data) {
		return;
	}
	const { type, displayName, parent, url } = activity.object.data;

	const typeCategory = getDocumentSubtypeLabel(type?.category || '');

	const id = activity.object.id;
	const title = displayName;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	if (!title || !url) {
		return undefined;
	}

	const icon = <ProductIcon size="small" product={ProductKeys.GoogleDrive} />;

	const typeLabel = [
		'type',
		<Box xcss={typeLabelContainer}>{typeCategory}</Box>,
	] satisfies SearchMetadataPart;

	const parentName = parent?.displayName || '';
	const metadata = [typeLabel, parentName] satisfies SearchMetadataPart[];

	const timestamp = activity.event.timestamp;
	const eventType = activity.event.eventType;
	const rightMetadata = (
		<EventTypeMessage timestamp={timestamp} eventType={eventType} isMyActivityData={true} />
	);
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const analyticsMetadata = {
		contentId: id,
		contentType: `graph ${activity.object.type}`,
	};

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

const mapGraphActivityToSearchResult = (
	activity: ActivityNode<QuickFindGraphEntity>,
	query: string,
) => {
	if (isDocumentActivity(activity.object)) {
		return mapGraphDocumentActivityToSearchResult(activity, query);
	}
	return undefined;
};

export const mapActivityToSearchResult = (
	edge: ActivityItemEdge,
	query: string,
	searchSessionId: string,
	showVisualRefresh: boolean,
) => {
	const activity = edge.node;
	const product = activity.object.product;
	const result = isGraphActivity(activity.object)
		? mapGraphActivityToSearchResult(activity, query)
		: product === 'confluence'
			? mapConfluenceActivityToSearchResult(activity, query, showVisualRefresh)
			: product === 'jira'
				? mapJiraActivityToSearchResult(activity, query, showVisualRefresh)
				: product === 'townsquare'
					? mapTownsquareActivityToSearchResult(activity, query, showVisualRefresh)
					: undefined;

	if (!result) {
		return undefined;
	}

	const urlWithAnalyticsQueryParams = getGenericUrlWithAnalyticsQueryParams({
		url: result.url,
		searchSessionId,
	});

	return {
		...result,
		url: urlWithAnalyticsQueryParams,
	};
};

type Props = {
	result: ActivityItemEdge;
	state: State;
	onClick?: (props: QuickFindResultEvent) => void;
	onContextMenu?: (props: QuickFindResultEvent) => void;
	onHighlighted?: (props: QuickFindResultEvent) => void;
};

export const RecentActivity = ({ result, state, onClick, onContextMenu, onHighlighted }: Props) => {
	const quickFindQuery = useQuickFindQuery();
	const [searchSessionId] = useSearchSessionId();
	const [showVisualRefresh] = useIsVisualRefreshEnabled();

	const mappedResult = mapActivityToSearchResult(
		result,
		quickFindQuery,
		searchSessionId,
		showVisualRefresh,
	);

	const onResultClicked = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onClick?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: {
					isCached: true,
					metadata: mappedResult?.analyticsMetadata,
				},
			});
		},
		[mappedResult?.analyticsMetadata, onClick, state],
	);

	const onResultContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onContextMenu?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: {
					isCached: true,
					metadata: mappedResult?.analyticsMetadata,
				},
			});
		},
		[mappedResult?.analyticsMetadata, onContextMenu, state],
	);

	const onResultHighlighted = useCallback(
		(data: SearchResultEventArguments) => {
			onHighlighted?.({
				data,
				state,
				additionalAnalyticsAttributes: {
					isCached: true,
					metadata: mappedResult?.analyticsMetadata,
				},
			});
		},
		[mappedResult?.analyticsMetadata, onHighlighted, state],
	);

	if (!mappedResult) {
		return null;
	}

	return (
		<SearchResult
			{...mappedResult}
			onResultClicked={onResultClicked}
			onResultContextMenu={onResultContextMenu}
			onResultHighlighted={onResultHighlighted}
		/>
	);
};
