import type { FC, ReactElement } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import type { AIAnswerQueryFunctionType } from '@atlassian/search-ai';

import { fg } from '@atlaskit/platform-feature-flags';

import { messages } from '../../../messages';
import { SearchDialogProduct, type TabRendererArgs } from '../../product-router';
import { useDefaultSuppliers } from '../../result-supplier';
import { type ExtensibleTabProps } from '../extensible-tab-types';
import {
	CONFLUENCE_PRODUCT_ID,
	ConfluenceFilters,
	type ConfluenceRecentsClientConfig,
	type ConfluenceSearchClientConfig,
	ConfluenceScope,
	NAVIGATION_V3_EXPERIENCE,
	PEOPLE_FILTER_LIMIT,
	SPACE_FILTER_LIMIT,
	type SupportedConfScopeResponses,
} from './types';
import { useConfluenceAdvancedSearchUrlFactory } from './utils';
import { useRecentsClient } from './recents-client';
import {
	type FilterLoader,
	type GenericFilterOption,
	useFilterStore,
	useGenericFilters,
	useSiteFilterDefinition,
	type SpaceFilterOption,
} from '../../filters';
import { FilterOptionSource } from '../../../common/filters/types';
import { useAnalytics } from '../../../common/analytics';
import {
	onSuggestedPromptClick,
	onPrequeryBannerDismiss,
	onPrequeryBannerShown,
} from '../../../common/analytics/events';
import { ProductStates } from '../../product-state-machine/product-state-machine-types';
import { useSearchConfigContext } from '../../../common/search-config-provider';
import { defaultPermissionsSupplier } from './permission-supplier';
import { ConfluenceReactivationExperimentTab } from './confluence-reactivation-experiment/confluence-reactivation-experiment-tab';
import { FilterOptionItem } from '../../product-router/product/result-provider/result-renderer/filter-section';
import { useConfluenceSections } from './sections';
import { useConfluenceSuppliers } from './confluence-item-suppliers';
import { useConfluenceDateFilter } from './filter-by-date-experiment/use-confluence-date-filter';
import { useDialogExpansionContext } from '../../dialog-expansion-context';
import { useQuery } from '../../query-context';
import { type RendererOverrideTypes } from '../confluence/types';
import { useSessionUserInput } from '../../user-input-provider';
import { useQueryParams } from '../../products/confluence/confluence-analytics-query-params';

export interface ExtensibleConfluenceTabProps extends ExtensibleTabProps {
	recentClientConfig: ConfluenceRecentsClientConfig;
	searchClientConfig?: ConfluenceSearchClientConfig;
	aiSearchConfig?: {
		aiSearchQueryFunction: AIAnswerQueryFunctionType;
		prequeryBannerEnabled?: boolean;
		dismissPrequeryBanner?: () => void;
	};
	formatDate?: (lastModified: string) => ReactElement;
	autocorrectEnabled?: boolean;
	additionalContentTypesEnabled?: boolean;
	rendererOverride?: RendererOverrideTypes;
	aiAdminNotificationHeader?: () => ReactElement;
	confluenceReactivationExperiment?: {
		showConfluenceReactivationTab?: boolean;
		isEligibleNewUser?: boolean;
	};
}

const InteractiveSearchAIOnboardingBanner = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/search-ai/banner" */ '@atlassian/search-ai/banner'
	).then(({ InteractiveSearchAIOnboardingBanner }) => ({
		default: InteractiveSearchAIOnboardingBanner,
	})),
);

export const ExtensibleConfluenceTab: FC<ExtensibleConfluenceTabProps> = ({
	recentClientConfig,
	searchClientConfig,
	aiSearchConfig,
	formatDate,
	children,
	autocorrectEnabled,
	additionalContentTypesEnabled = false,
	rendererOverride,
	aiAdminNotificationHeader,
	confluenceReactivationExperiment,
	...rest
}) => {
	const { formatMessage } = useIntl();
	const { fireAnalyticsEvent } = useAnalytics();
	const { setQuery } = useQuery();
	const { toggleShowSearchAIDialog } = useSessionUserInput();
	const { confluenceSites } = useSearchConfigContext();
	const { defineFilters } = useGenericFilters();
	const { isUserAnonymous } = recentClientConfig;
	const { searchClient } = useRecentsClient(recentClientConfig);
	const { isExpanded } = useDialogExpansionContext();
	const appendAnalyticsQueryParams = useQueryParams();

	const isEnhancedSearchAnalyticsEnabled = fg('cc_page_experiences_enhanced_search_analytics_conf');
	const appendQueryParams = isEnhancedSearchAnalyticsEnabled
		? appendAnalyticsQueryParams
		: undefined;

	const siteFilterDefinition = useSiteFilterDefinition(
		rest.workspaces || [],
		CONFLUENCE_PRODUCT_ID,
	);

	const { confluenceTabDateFilter, appliedDateFilter } = useConfluenceDateFilter();

	const spaceFilterStore = useFilterStore<SpaceFilterOption>(
		[],
		CONFLUENCE_PRODUCT_ID,
		ConfluenceFilters.Space,
	);
	const peopleFilterStore = useFilterStore<GenericFilterOption>(
		[],
		CONFLUENCE_PRODUCT_ID,
		ConfluenceFilters.People,
	);

	const { preQueryItemSupplier, postQueryItemSupplier } = useConfluenceSuppliers({
		recentClientConfig,
		searchClientConfig,
		autocorrectEnabled,
		spaceFilterStore,
		peopleFilterStore,
		isExpanded,
		isUserAnonymous,
		formatDate,
		rendererOverride,
		additionalContentTypesEnabled,
	});

	const sections = useConfluenceSections({
		formatDate,
		autocorrectEnabled,
		additionalContentTypesEnabled,
		rendererOverride,
	});

	const itemSuppliers = useDefaultSuppliers<SupportedConfScopeResponses, ConfluenceScope | string>(
		CONFLUENCE_PRODUCT_ID,
		sections,
		{
			preQueryItemSupplier: rest.preQueryItemSupplier || preQueryItemSupplier,
			postQueryItemSupplier: rest.postQueryItemSupplier || postQueryItemSupplier,
		},
	);

	const confluenceAdvancedSearchUrlFactory = useConfluenceAdvancedSearchUrlFactory(
		recentClientConfig.url,
		{
			spaceFilters: spaceFilterStore.availableFilters
				.filter((f) => f.isChecked)
				.map((f) => f.spaceKey || ''),
			peopleFilters: peopleFilterStore.availableFilters.filter((f) => f.isChecked).map((f) => f.id),
			dateFilter: !!appliedDateFilter ? [appliedDateFilter] : [],
		},
	);

	const recentSectionTitleGenerator = useCallback((section: { title: string }) => {
		return section.title;
	}, []);

	const loadSpaceFilters: FilterLoader = useCallback(
		(query: string) => {
			return isUserAnonymous || !searchClient
				? Promise.resolve([])
				: searchClient
						.search({
							query,
							context: {
								sessionId: '',
								referrerId: null,
							},
							scopes: [ConfluenceScope.Space],
							modelParams: [],
							resultLimit: SPACE_FILTER_LIMIT,
							experience: NAVIGATION_V3_EXPERIENCE,
							sites: [],
						})
						.then(({ response }) => {
							const results = response.retrieveScope(ConfluenceScope.Space)?.results;
							return (
								results?.map((result) => ({
									value: {
										id: result.id,
										spaceKey: result.space?.key || '',
										avatarUrl: `${result.baseUrl}${result.space!.icon.path}`,
										label: result.title,
										filterSource: FilterOptionSource.SEARCH,
										isChecked: false,
										isVisible: true,
									},
									label: (
										<FilterOptionItem
											label={result.title}
											iconProps={{
												src: `${result.baseUrl}${result.space!.icon.path}`,
												appearance: 'square',
											}}
										/>
									),
								})) || []
							);
						});
		},
		[searchClient, isUserAnonymous],
	);

	const loadPeopleFilters: FilterLoader = useCallback(
		(query: string) => {
			return isUserAnonymous || !searchClient
				? Promise.resolve([])
				: searchClient
						.search({
							query,
							context: {
								sessionId: '',
								referrerId: null,
							},
							scopes: [ConfluenceScope.People],
							modelParams: [],
							resultLimit: PEOPLE_FILTER_LIMIT,
							experience: NAVIGATION_V3_EXPERIENCE,
							sites: [],
						})
						.then(({ response }) => {
							const results = response.retrieveScope(ConfluenceScope.People)?.results;
							return (
								results?.map((result) => ({
									value: {
										id: result.id,
										avatarUrl: result.avatarUrl,
										label: result.name,
										filterSource: FilterOptionSource.SEARCH,
										isChecked: false,
										isVisible: true,
									},
									label: (
										<FilterOptionItem label={result.name} iconProps={{ src: result.avatarUrl }} />
									),
								})) || []
							);
						});
		},
		[searchClient, isUserAnonymous],
	);

	const confluenceTabGenericFilters = useMemo(() => {
		return [
			{
				['@type']: ConfluenceFilters.Space,
				fieldName: 'spaceKeys',
				store: spaceFilterStore,
				loadFilterOptions: loadSpaceFilters,
				sectionLabel: formatMessage(messages.space_filters_title),
				findMoreLabel: formatMessage(messages.space_filters_find_more),
			},
			{
				['@type']: ConfluenceFilters.People,
				fieldName: 'accountIds',
				store: peopleFilterStore,
				loadFilterOptions: loadPeopleFilters,
				sectionLabel: formatMessage(messages.contributor_filters_title),
				findMoreLabel: formatMessage(messages.contributor_filters_find_more),
				filterConfig: {
					filterOptionIconShape: 'circle' as const,
				},
			},
		];
	}, [formatMessage, loadPeopleFilters, loadSpaceFilters, peopleFilterStore, spaceFilterStore]);

	const filtersToDefine = useMemo(() => {
		return [...confluenceTabDateFilter, ...siteFilterDefinition, ...confluenceTabGenericFilters];
	}, [confluenceTabGenericFilters, confluenceTabDateFilter, siteFilterDefinition]);

	const onPrequeryBannerRender = useCallback(() => {
		fireAnalyticsEvent(onPrequeryBannerShown());
	}, [fireAnalyticsEvent]);

	const onPromptSuggestionClick = useCallback(
		(prompt: string, promptId: string) => {
			fireAnalyticsEvent(onSuggestedPromptClick(promptId));
			setQuery(prompt);
			toggleShowSearchAIDialog(true);
		},
		[fireAnalyticsEvent, setQuery, toggleShowSearchAIDialog],
	);

	const dismissOnboardingBanner = useCallback(() => {
		if (aiSearchConfig?.dismissPrequeryBanner) {
			fireAnalyticsEvent(onPrequeryBannerDismiss());
			aiSearchConfig.dismissPrequeryBanner();
		}
	}, [aiSearchConfig, fireAnalyticsEvent]);

	const initializeFilters = useCallback(() => {
		defineFilters(CONFLUENCE_PRODUCT_ID, filtersToDefine);
	}, [defineFilters, filtersToDefine]);

	initializeFilters();

	const { showConfluenceReactivationTab, isEligibleNewUser } = confluenceReactivationExperiment ?? {
		showConfluenceReactivationTab: false,
		isEligibleNewUser: false,
	};

	const confluenceReactivationExperimentTabRenderer = useCallback(
		({ title }: TabRendererArgs) => (
			<ConfluenceReactivationExperimentTab title={title} isEligibleNewUser={isEligibleNewUser} />
		),
		[isEligibleNewUser],
	);

	return (
		<SearchDialogProduct
			id={CONFLUENCE_PRODUCT_ID}
			expandedStateInputPlaceholder={
				!!aiSearchConfig
					? formatMessage(messages.confluence_search_input_expanded_placeholder_ai)
					: formatMessage(messages.confluence_search_input_expanded_placeholder)
			}
			title={formatMessage(messages.confluence_tab_label)}
			tabRenderer={
				showConfluenceReactivationTab ? confluenceReactivationExperimentTabRenderer : undefined
			}
			autocorrectEnabled={autocorrectEnabled}
			sections={sections}
			generateAdvancedSearchUrl={confluenceAdvancedSearchUrlFactory}
			preQuerySectionTitleGenerator={recentSectionTitleGenerator}
			showViewAllFiltersLink={true}
			{...rest}
			{...itemSuppliers}
			aiSearchConfig={aiSearchConfig}
			permissionSupplier={
				confluenceSites.length > 0 ? defaultPermissionsSupplier : rest.permissionSupplier
			}
			appendQueryParams={appendQueryParams}
		>
			{({ productState }) => {
				if (productState === ProductStates.PreQuerySuccess) {
					if (!!aiAdminNotificationHeader) {
						return {
							Header: aiAdminNotificationHeader,
						};
					}
					if (!!aiSearchConfig && aiSearchConfig.prequeryBannerEnabled) {
						return {
							Header: () => (
								<InteractiveSearchAIOnboardingBanner
									onPromptSuggestionClick={onPromptSuggestionClick}
									onXButtonClick={dismissOnboardingBanner}
									onBannerShown={onPrequeryBannerRender}
								/>
							),
						};
					}
				}
				return {};
			}}
		</SearchDialogProduct>
	);
};
