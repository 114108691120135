import React, { Suspense } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl-next';

import { messages } from './messages';

import { Flex } from '@atlaskit/primitives';

const Lozenge = React.lazy(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "@atlaskit-internal_@atlassian/lozenge" */ '@atlaskit/lozenge'
	).then((module) => {
		return {
			default: module.default,
		};
	}),
);

interface ConfluenceReactivationExperimentTabProps {
	title: string;
	isEligibleNewUser: boolean | undefined;
}

const Tab = ({
	intl,
	title,
	isEligibleNewUser,
}: ConfluenceReactivationExperimentTabProps & WrappedComponentProps) => (
	<Flex alignItems="center" columnGap="space.100">
		{title}
		<Suspense fallback={null}>
			<Lozenge appearance="new">
				{intl.formatMessage(
					isEligibleNewUser ? messages.never_active_user : messages.inactive_user,
				)}
			</Lozenge>
		</Suspense>
	</Flex>
);

export const ConfluenceReactivationExperimentTab = injectIntl(Tab);
