import React, { useCallback } from 'react';

import { FormattedMessage, type MessageDescriptor } from 'react-intl-next';

import CompassIcon from '@atlaskit/icon/core/compass';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import { N900 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import {
	useQuickFindActions,
	useQuickFindQuery,
} from '../../../../../controllers/store/quick-find';

import { messages } from './messages';

const styles = xcss({ height: '100%', display: 'inline-block' });
const singleSiteContainter = xcss({
	padding: 'space.100',
});

const iconContainer = xcss({
	marginLeft: 'space.150',
	marginRight: 'space.150',
});

const labelContainer = xcss({
	marginRight: 'space.100',
	marginLeft: 'space.050',
	color: 'color.text',
	':hover': {
		color: 'color.text',
	},
	':focus': {
		color: 'color.text',
	},
});

const styleLink = xcss({
	WebkitBoxAlign: 'baseline',
	alignItems: 'center',
	boxSizing: 'border-box',
	display: 'inline-flex',
	justifyContent: 'center',
	fontWeight: 'font.weight.medium',
	textAlign: 'center',
	whiteSpace: 'nowrap',
	border: `2px solid ${token('color.border', N900)}`,
	borderRadius: '4px',
	paddingTop: 'space.025',
	paddingBottom: 'space.025',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	marginRight: 'space.100',
	color: 'color.text.subtle',
	textDecoration: 'none',
	transition: 'background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	':hover': {
		color: 'color.text.subtle',
		backgroundColor: 'color.background.neutral.hovered',
		textDecoration: 'none',
		transition: 'background 0s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	},
	':visited': {
		color: 'color.text.subtle',
	},
	':focus-visible': {
		color: 'color.text.subtle',
	},
	':focus': {
		color: 'color.text.subtle',
	},
});

type SearchConfiguration = {
	href: string;
	message: MessageDescriptor;
};

export const getSearchConfiguration: (query: string) => { [key: string]: SearchConfiguration } = (
	query,
) => {
	const allLinks: { [key: string]: SearchConfiguration } = {
		board: {
			href: `/secure/ManageRapidViews.jspa?contains=${query}`,
			message: messages.jira_quick_find_boards,
		},
		project: {
			href: `/projects?contains=${query}`,
			message: messages.jira_quick_find_projects,
		},
		filters: {
			href: `/secure/ManageFilters.jspa?Search=Search&filterView=search&name=${query}`,
			message: messages.jira_quick_find_filters,
		},
		plans: {
			href: `/jira/plans`,
			message: messages.jira_quick_find_plans,
		},
		people: {
			href: `/jira/people/search?q=${query}`,
			message: messages.jira_quick_find_people,
		},
	};

	return allLinks;
};

export const JiraSingleSiteSearch = () => {
	const quickFindQuery = useQuickFindQuery();
	const { setQuickFindOpen } = useQuickFindActions();

	const searchConfiguration = getSearchConfiguration(quickFindQuery);

	const onClick = useCallback(() => {
		setQuickFindOpen(false);
	}, [setQuickFindOpen]);

	return (
		<Box xcss={singleSiteContainter}>
			<Box as="span" xcss={iconContainer}>
				{/* For now we are using CompassIcon until a custom navigation icon is made for this */}
				<CompassIcon label="navigate-single-site-icon" />
			</Box>
			<Box as="span" xcss={labelContainer}>
				<FormattedMessage {...messages.jira_quick_find_single_site_label} />
			</Box>
			{Object.keys(searchConfiguration).map((key) => {
				const { href, message } = searchConfiguration[key];
				return (
					<Box key={`single_site_${key}`} role="none" xcss={styles}>
						<Anchor xcss={styleLink} href={href} onClick={onClick}>
							<FormattedMessage {...message} />
						</Anchor>
					</Box>
				);
			})}
		</Box>
	);
};

export default JiraSingleSiteSearch;
