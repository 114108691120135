import React, { type PropsWithChildren } from 'react';
import { ThemeProvider } from '@atlassian/search-dialog';
import { DialogExpansionContextProvider } from '../dialog-expansion-context';
import { QueryContextProvider } from '../query-context';
import { ProductRouter } from '../product-router';
import { MessagesIntlProvider } from '../../common/message-intl-provider';
import { BuildVersionAnalyticContext } from '../../common/build-version-analytic-context';
import { type SearchCSS } from '@atlassian/search-dialog';
import { SearchSessionProvider } from '../../common/search-session-provider';
import {
	AggregatorClientContextProvider,
	type AggregatorClientFeatures,
} from '../aggregator-client-context';
import { type AbTestAttribute, ABTestContextProvider } from '../ab-test-context';
import { ExperimentExposureHandler } from '../../common/experiment-exposure-handler';
import { DialogDismissedHandler } from '../../common/analytics';
import { type UserDetails, UserProvider } from '../../common/user-context';
import { AsyncLoadOnQuery } from './async-load/async-load-on-query';
import { FilterContextHOC } from '../filters/filter-context';
import { AutocorrectionProvider } from '../autocorrection';
import { DialogShownHandler } from '../../common/analytics/dialog-shown-handler';
import { type DialogFeatures, FeaturesProvider } from '../features-context';
import { QuickFindAnalyticContext } from '../../common/quick-find-analytic-context';
import { InputContext } from '../input-context';
import { RovoContextProvider } from '../../common/rovo-context/rovo-context';
import { type ParsedUrlQuery } from '../../common/shared/types';
import { QueryParamsProvider } from '../query-params-context';

export interface MetaContextProviderProps {
	/**
	 * Used to override the default AtlasKit Search Navigation CSS theming.
	 */
	theme?: SearchCSS;
	/**
	 * Flag which indicates the current open state of the dialog. Used internally within the dialog for analytics.
	 */
	isExpanded: boolean;
	/**
	 * Sets the current open state of the dialog.
	 */
	setIsExpanded: (value: boolean) => void;
	/**
	 * Cloud id used for the current abTest.
	 */
	abTestCloudId: string;
	/**
	 * Url for aggregator for quicksearch supported products.
	 */
	aggregatorUrl: string;
	/**
	 * Used to provide customisation options to internal clients which interact with aggregator.
	 */
	clientProviderFeatures?: AggregatorClientFeatures;
	/**
	 * Details for the current user.
	 */
	user?: UserDetails;

	dialogFeatures?: DialogFeatures;

	primaryProduct?: string;

	forwardRef?: React.Ref<HTMLInputElement>;

	/*
	 * This prop is used to render the Rovo tip banner in Quick Find
	 */
	isRovoEnabled?: boolean;

	/**
	 * Used to support Griffin navigation (nav4)
	 */
	queryParams?: ParsedUrlQuery;
}

export type Props = PropsWithChildren<AbTestAttribute & MetaContextProviderProps>;

export const MetaContextProvider = ({
	children,
	theme,
	setIsExpanded,
	isExpanded,
	abTestCloudId,
	aggregatorUrl,
	experience,
	clientProviderFeatures,
	user,
	dialogFeatures,
	primaryProduct,
	forwardRef,
	isRovoEnabled,
	queryParams,
}: Props) => {
	return (
		<QueryParamsProvider queryParams={queryParams}>
			<FeaturesProvider dialogFeatures={dialogFeatures}>
				<UserProvider user={user}>
					<RovoContextProvider isEnabled={isRovoEnabled}>
						<MessagesIntlProvider>
							<QuickFindAnalyticContext isQuickFind={dialogFeatures?.quickFind?.enabled}>
								<BuildVersionAnalyticContext>
									<ThemeProvider partialSearchCSS={theme}>
										<DialogExpansionContextProvider
											isExpanded={isExpanded}
											setIsExpanded={setIsExpanded}
										>
											<ProductRouter primaryProduct={primaryProduct}>
												<AggregatorClientContextProvider
													abTestCloudId={abTestCloudId}
													aggregatorUrl={aggregatorUrl}
													features={clientProviderFeatures}
												>
													<ABTestContextProvider experience={experience}>
														<SearchSessionProvider sessionKey={`ssid_provider_${isExpanded}`}>
															{isExpanded ? <ExperimentExposureHandler /> : null}
															<QueryContextProvider>
																<FilterContextHOC>
																	<AutocorrectionProvider>
																		<InputContext forwardRef={forwardRef}>
																			<AsyncLoadOnQuery />
																			<DialogShownHandler isExpanded={isExpanded} />
																			<DialogDismissedHandler isExpanded={isExpanded} />
																			{children}
																		</InputContext>
																	</AutocorrectionProvider>
																</FilterContextHOC>
															</QueryContextProvider>
														</SearchSessionProvider>
													</ABTestContextProvider>
												</AggregatorClientContextProvider>
											</ProductRouter>
										</DialogExpansionContextProvider>
									</ThemeProvider>
								</BuildVersionAnalyticContext>
							</QuickFindAnalyticContext>
						</MessagesIntlProvider>
					</RovoContextProvider>
				</UserProvider>
			</FeaturesProvider>
		</QueryParamsProvider>
	);
};
