import React from 'react';
import { useIntl } from 'react-intl-next';

import AdvancedSearchFooter, { type AdvancedSearchFooterProps } from '../../advanced-search-footer';
import { messages } from '../messages';
import { QuickFindAdvancedSearchLink } from './quick-find-footer.styled';
import { PersistRecentSearch } from '../../recent-searches/persist-recent-search';

export const SearchFooter = ({ linkComponent }: AdvancedSearchFooterProps) => {
	const { formatMessage } = useIntl();
	return (
		<QuickFindAdvancedSearchLink>
			<AdvancedSearchFooter
				linkComponent={linkComponent}
				squareReturnIcon
				searchFooterLabel={formatMessage(messages.quick_find_footer_full_page_search_link)}
			/>
		</QuickFindAdvancedSearchLink>
	);
};

// wraps the search footer with persistRecentSearch
export const SearchFooterPersistRecentSearch = ({ linkComponent }: AdvancedSearchFooterProps) => {
	return (
		<PersistRecentSearch>
			<SearchFooter linkComponent={linkComponent} />
		</PersistRecentSearch>
	);
};
