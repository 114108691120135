import React from 'react';
import AdvancedSearchFooter, {
	type AdvancedSearchFooterProps,
} from '../../../../advanced-search-footer';
import { type ProductStates } from '../../../../product-state-machine';
import { SearchFooter } from '@atlassian/search-dialog';

export const GetDefaultFooter = ({
	productState,
	...rest
}: {
	productState: ProductStates;
} & AdvancedSearchFooterProps) => {
	return (
		<SearchFooter>
			<AdvancedSearchFooter {...rest} />
		</SearchFooter>
	);
};
