import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl-next';

import Avatar from '@atlaskit/avatar';
import { Inline } from '@atlaskit/primitives';
import { EntityType, type UserSearchItem } from '@atlaskit/smart-common';
import {
	getGenericUrlWithAnalyticsQueryParams,
	getTeamPath,
	getUserPath,
	highlightMatchedText,
} from '@atlassian/search-common';

import { ReturnIcon } from '../../../../common/constants/icons/return';
import { useAppContext } from '../../../../common/ui/app-context';
import {
	SearchResult,
	type SearchResultEventArguments,
} from '../../../../common/ui/quick-find/search-dialog-result';
import { type SearchMetadataPart } from '../../../../common/ui/search-metadata';
import { type State } from '../../../../common/utils/quick-find/state';
import { type QuickFindResultEvent } from '../../../../controllers/quick-find/utils';
import {
	useCloudId,
	useOrgId,
	usePrimaryProduct,
	useSearchSessionId,
} from '../../../../controllers/store';
import { useQuickFindQuery } from '../../../../controllers/store/quick-find';

import { messages } from './messages';

type Props = {
	result: UserSearchItem;
	state: State;
	onClick?: (props: QuickFindResultEvent) => void;
	onContextMenu?: (props: QuickFindResultEvent) => void;
	onHighlighted?: (props: QuickFindResultEvent) => void;
};

export const PeopleAndTeams = ({ result, state, onClick, onContextMenu, onHighlighted }: Props) => {
	const [cloudId] = useCloudId();
	const [orgId] = useOrgId();
	const [primaryProduct] = usePrimaryProduct();
	const quickFindQuery = useQuickFindQuery();
	const [searchSessionId] = useSearchSessionId();
	const { generateTeamUrl, generateUserUrl } = useAppContext();

	const id = result.id;

	const userTitle = result.name;
	const teamTitle = result.displayName;
	const title = result.entityType === EntityType.USER ? userTitle : teamTitle;

	const highlightedTitle =
		title && quickFindQuery
			? highlightMatchedText(title, quickFindQuery.split(' ')).highlightedTitle
			: undefined;

	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			<ReturnIcon />
		</Inline>
	);

	const icon = (
		<Avatar
			appearance={result.entityType === EntityType.USER ? 'circle' : 'square'}
			borderColor="transparent"
			name={title}
			size={'small'}
			src={result.avatarUrl || result.smallAvatarImageUrl}
		/>
	);

	const userMetadata = ['user-metadata', result.title] satisfies SearchMetadataPart;
	const teamMetadata = [
		'team-metadata',
		<FormattedMessage {...messages.team} />,
	] satisfies SearchMetadataPart;
	const metadata = result.entityType === EntityType.USER ? [userMetadata] : [teamMetadata];

	let path;
	// TODO remove this statement once all products have specified their own generateUserUrl and generateTeamUrl props
	if (primaryProduct && orgId && cloudId) {
		path =
			result.entityType === EntityType.USER
				? getUserPath(result.id, primaryProduct, orgId, cloudId)
				: getTeamPath(result.id, primaryProduct, orgId, cloudId);
	}

	if (generateUserUrl && result.entityType === EntityType.USER) {
		path = generateUserUrl(result.id);
	} else if (generateTeamUrl && result.entityType !== EntityType.USER) {
		path = generateTeamUrl(result.id);
	}

	const url = path && new URL(path, window.location.origin).href;

	const analyticsMetadata = useMemo(
		() => ({
			contentId: id,
			contentType: 'user',
		}),
		[id],
	);

	const onResultClicked = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onClick?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: {
					metadata: analyticsMetadata,
				},
			});
		},
		[analyticsMetadata, onClick, state],
	);

	const onResultContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onContextMenu?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: {
					metadata: analyticsMetadata,
				},
			});
		},
		[analyticsMetadata, onContextMenu, state],
	);

	const onResultHighlighted = useCallback(
		(data: SearchResultEventArguments) => {
			onHighlighted?.({
				data,
				state,
				additionalAnalyticsAttributes: {
					metadata: analyticsMetadata,
				},
			});
		},
		[analyticsMetadata, onHighlighted, state],
	);

	if (!title || !metadata || !url) {
		return null;
	}

	const urlWithAnalyticsQueryParams = getGenericUrlWithAnalyticsQueryParams({
		url,
		searchSessionId,
	});

	return (
		<SearchResult
			id={id}
			icon={icon}
			title={title}
			highlightedTitle={highlightedTitle}
			metadata={metadata}
			highlightedRightMetadata={highlightedRightMetadata}
			url={urlWithAnalyticsQueryParams}
			onResultClicked={onResultClicked}
			onResultContextMenu={onResultContextMenu}
			onResultHighlighted={onResultHighlighted}
		/>
	);
};
