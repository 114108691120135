import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import Link from '@atlaskit/link';
import { Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';

import { useQuickFindActions } from '../../../../../controllers/store/quick-find';

import { messages } from './messages';

export const AssetsUnavailableBanner = () => {
	const { formatMessage } = useIntl();
	const { setQuickFindOpen } = useQuickFindActions();

	const assetsMessage = formatMessage(messages.assets);
	const assetsSearchMessage = formatMessage(messages.assetsSearch);

	return (
		<Box xcss={containerStyles}>
			<SectionMessage>
				<FormattedMessage
					{...messages.assetsUnavailableInfo}
					values={{
						assets: <Text weight={'bold'}>{assetsMessage}</Text>,
						assetsSearch: (
							<Link href={'/jira/assets?showSearch=true'} onClick={() => setQuickFindOpen(false)}>
								{assetsSearchMessage}
							</Link>
						),
					}}
				/>
			</SectionMessage>
		</Box>
	);
};

const containerStyles = xcss({
	paddingBottom: 'space.075',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});
