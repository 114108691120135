import type { KeyboardEvent, MutableRefObject } from 'react';
import React, { useRef, useState } from 'react';
import type { IntlShape } from 'react-intl-next';
import { FormattedMessage, injectIntl } from 'react-intl-next';
import EngagementSpotlight from '@atlassiansox/engagekit-ts/ui/Spotlight';

import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import type { SearchCSS } from '@atlaskit/atlassian-navigation';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { ConfluenceFeatures, JiraFeatures } from '@atlassian/product-search-dialog';

import { AddActivationIdWrapper } from '@confluence/analytics';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { TimeAgo } from '@confluence/time-ago';
import {
	type FeatureFlagsType,
	SessionData,
	type SessionDataChildrenFn,
} from '@confluence/session-data';
import {
	GeneralShortcutListener,
	NAVIGATION_SHORTCUT,
	NAVIGATION_SLASH_SHORTCUT,
} from '@confluence/shortcuts';
import { PageSubscriber } from '@confluence/page-context';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import type { ClientProviderConfig } from './ClientProvider';
import { ClientProvider } from './ClientProvider';
import { getResultLinkComponent } from './ResultLinkComponent';
import type { UserContextDataPayload } from './UserContext';
import { UserContext } from './UserContext';
import { CurrentSpaceContext } from './CurrentSpaceContext';
import { QuickFindNav3 } from './QuickFindNav3';
import {
	generateInteractiveSkeletonOnEnter,
	InteractiveSearchInputSkeleton,
} from './InteractiveSearchInputSkeleton';
import { ExtensibleCrossProductSearchDialog } from './ExtensibleCrossProductSearchDialog';
import { i18n } from './messages';
import { useQuickFindEnabled } from './experiment/QuickFindExperiment';

export const SEARCH_SPOTLIGHT_KEY = 'search-spotlight';

export interface NavigationSearchDialogProps {
	theme?: SearchCSS;
	clearStickySearchRef?: MutableRefObject<() => void>;
	shouldFillContainer?: boolean;
}

export const AUTO_ADD_CURRENT_SPACE_FLAG = 'confluence.frontend.current-space-auto-add';

export const ENABLE_URS_FOR_PEOPLE_SEARCH =
	'confluence.frontend.search-dialog.use-urs-for-people-search';

export const formatDate = (date: string) => {
	const timeAgo = <TimeAgo date={date} dateFormatOptions={{ month: 'short' }} />;

	return <FormattedMessage {...i18n.lastUpdatedDate} values={{ datetime: timeAgo }} />;
};

const analyticsContainerStyles = xcss({
	width: '100%',
});

export type LinkComponent = React.ComponentType<{
	href?: string;
	target?: '_blank' | '_self' | '_parent' | '_top';
	className?: string;
	isKeyboardHighlighted?: boolean;
	isCollapsed?: boolean;
}>;

interface InnerProps {
	isExpanded: boolean;
	onOpen: (e?: KeyboardEvent | React.MouseEvent<Element>) => void;
	onClose: () => void;
	formatDate: (lastModified: string) => JSX.Element;
	ref: React.RefObject<HTMLInputElement>;
	theme?: SearchCSS;
	confluenceFeatures?: ConfluenceFeatures;
	jiraFeatures?: JiraFeatures;
	user: UserContextDataPayload;
	onNavigate: (url: string) => void;
	linkComponent: LinkComponent;
	shouldFillContainer?: boolean;
}

const NavigationSearchDialogWithRouter: React.FC<
	{ intl: IntlShape } & NavigationSearchDialogProps
> = ({ theme, shouldFillContainer, intl, clearStickySearchRef }) => {
	const [isOpen, setIsOpen] = useState(false);
	const searchInputRef = useRef<HTMLInputElement>(null);
	const { push } = useRouteActions();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	const openDialog = (
		e:
			| Mousetrap.ExtendedKeyboardEvent
			| KeyboardEvent<Element>
			| React.MouseEvent<Element>
			| null
			| undefined,
	) => {
		if (e) {
			e.preventDefault(); //prevent shortcut key from being added to search input
			searchInputRef.current?.focus();
		}
		setIsOpen(true);
	};

	const closeDialog = () => {
		setIsOpen(false);
	};

	const onResultLinkClick = () => {
		closeDialog();
	};

	const onNavigate = (url: string) => {
		closeDialog();
		push(url);
	};

	const onInteractiveSkeletonEnter = generateInteractiveSkeletonOnEnter(push);

	const isNav4Enabled = useIsNav4Enabled();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'search',
				source: 'globalNavigation',
				attributes: {
					navigationLayer: 'global',
					navigationContainer: 'top',
					navVersion: isNav4Enabled ? '4' : '3',
				},
			},
		}).fire();
	};

	const getRootSearchDialog = (
		{ ref, ...rest }: InnerProps,
		clientProviderConfig: ClientProviderConfig,
	) => {
		const { cloudId, aggregatorUrl } = clientProviderConfig;
		const interactiveSkeleton = {
			onEnter: onInteractiveSkeletonEnter,
			placeholder: intl.formatMessage(i18n.placeholder),
		};

		return (
			<>
				<SpotlightTarget name={SEARCH_SPOTLIGHT_KEY}>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
					<Box xcss={analyticsContainerStyles} onClick={fireClickAnalytics}>
						<AddActivationIdWrapper
							channel="fabric-elements"
							targetActionSubjects={['postQuerySearchResults', 'cachedResults']}
						>
							<ExtensibleCrossProductSearchDialog
								jiraFeatures={{ hasSoftwareAccess: true }}
								forwardRef={ref}
								setIsExpanded={(value: boolean) => (value ? openDialog(null) : closeDialog())}
								cloudId={cloudId}
								aggregatorUrl={aggregatorUrl}
								onNavigateSecondary={(href: string) => {
									// eslint-disable-next-line no-restricted-syntax
									window.location.assign(href);
								}}
								features={{
									jiraFeatures: rest.jiraFeatures,
									confluenceFeatures: rest.confluenceFeatures,
									interactiveSkeleton,
								}}
								clearStickySearchRef={clearStickySearchRef}
								{...rest}
							/>
						</AddActivationIdWrapper>
					</Box>
				</SpotlightTarget>
				<EngagementSpotlight engagementId={SEARCH_SPOTLIGHT_KEY} />
			</>
		);
	};

	const getSearchDialog = (
		searchDialogProps: InnerProps,
		featureFlags: FeatureFlagsType,
		clientProviderConfig: ClientProviderConfig,
	) => {
		const autoAddCurrentSpaceIsEnabled = featureFlags[AUTO_ADD_CURRENT_SPACE_FLAG];

		return (
			<PageSubscriber>
				{({ spaceKey }) => {
					return !!spaceKey && autoAddCurrentSpaceIsEnabled ? (
						<CurrentSpaceContext spaceKey={spaceKey}>
							{({ currentSpace, loading }) => {
								if (loading) {
									return (
										<InteractiveSearchInputSkeleton
											theme={searchDialogProps.theme}
											shouldFillContainer={searchDialogProps.shouldFillContainer}
										/>
									);
								} else {
									const currentSpaceValid =
										currentSpace && Object.values(currentSpace).every((v) => v !== undefined);
									return getRootSearchDialog(
										{
											...searchDialogProps,
											confluenceFeatures: {
												currentSpace: currentSpaceValid
													? (currentSpace as ConfluenceFeatures['currentSpace'])
													: undefined,
											},
										},
										clientProviderConfig,
									);
								}
							}}
						</CurrentSpaceContext>
					) : (
						getRootSearchDialog(
							{
								...searchDialogProps,
							},
							clientProviderConfig,
						)
					);
				}}
			</PageSubscriber>
		);
	};

	return (
		<>
			<SessionData>
				{
					(({ cloudId, userId, featureFlags }) => {
						return (
							<ClientProvider
								cloudId={cloudId}
								userId={userId!}
								useUrsForPeopleSearch={featureFlags[ENABLE_URS_FOR_PEOPLE_SEARCH] as any}
							>
								{({ clientProviderConfig }) => (
									<ErrorBoundary attribution={Attribution.DISCO} modal>
										<UserContext>
											{({ data, isLoading }) => {
												return isLoading ? (
													<InteractiveSearchInputSkeleton
														theme={theme}
														shouldFillContainer={shouldFillContainer}
													/>
												) : (
													getSearchDialog(
														{
															isExpanded: isOpen,
															onOpen: openDialog,
															onClose: closeDialog,
															linkComponent: getResultLinkComponent(onResultLinkClick),
															formatDate,
															ref: searchInputRef,
															theme,
															user: {
																name: data && data.name,
																email: data && data.email,
																id: data && data.id,
															},
															onNavigate,
															shouldFillContainer,
														},
														featureFlags,
														clientProviderConfig,
													)
												);
											}}
										</UserContext>
									</ErrorBoundary>
								)}
							</ClientProvider>
						);
					}) as SessionDataChildrenFn
				}
			</SessionData>
			{!isLiveEditMode && !isOnEditRoute && (
				<GeneralShortcutListener
					accelerator={[NAVIGATION_SHORTCUT, NAVIGATION_SLASH_SHORTCUT]}
					listener={openDialog}
					isAppNavigationShortcut
				/>
			)}
		</>
	);
};

const NavigationSearchDialogInternal = injectIntl(NavigationSearchDialogWithRouter);

const NavigationSearchDialog = (props: NavigationSearchDialogProps) => {
	const [isQuickFindEnabled] = useQuickFindEnabled();
	return isQuickFindEnabled && fg('quick_find_refactor') ? (
		<QuickFindNav3 />
	) : (
		<NavigationSearchDialogInternal {...props} />
	);
};

export { NavigationSearchDialog };
