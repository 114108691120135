import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl-next';

import { ConfluenceIcon } from '@atlaskit/logo';

import AdvancedSearchFooter from '../../advanced-search-footer';
import { messages } from '../messages';
import { QuickFindAdvancedSearchLink } from './quick-find-footer.styled';
import { PersistRecentSearch } from '../../recent-searches/persist-recent-search';

export const FilterFooter = () => {
	const { formatMessage } = useIntl();
	const confluenceIcon = <ConfluenceIcon appearance="brand" size="small" />;
	return (
		<PersistRecentSearch>
			<QuickFindAdvancedSearchLink>
				<AdvancedSearchFooter
					squareReturnIcon
					searchFooterLabel={formatMessage(messages.quick_find_show_filtered_results)}
					searchFooterLabelWithIcon={
						<FormattedMessage
							{...messages.quick_find_show_filtered_results_with_icon}
							values={{ confluenceIcon }}
						/>
					}
					filterByProductFooter={true}
				/>
			</QuickFindAdvancedSearchLink>
		</PersistRecentSearch>
	);
};
