import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	quickFindNoContentHeading: {
		id: 'search_dialog_no_content_heading',
		defaultMessage: 'Seek and you shall find',
		description:
			'Header of prompt to begin search journey if no recent content results are rendered',
	},
	quickFindNoContentBodyWithRovo: {
		id: 'search_dialog_no_content_body_with_rovo',
		defaultMessage:
			"Search or ask Rovo what you're looking for in your Atlassian and integrated products",
		description:
			'Body text of prompt to begin search journey if no recent content results are rendered for Rovo',
	},
	quickFindNoContentBodyWithAtlassianIntelligence: {
		id: 'search_dialog_no_content_body_with_atlassian_intelligence',
		defaultMessage: 'Search or ask anything across your Atlassian products',
		description:
			'Body text of prompt to begin search journey if no recent content results are rendered, for non-Rovo users with AI enabled',
	},
	quickFindNoContentBodyDefault: {
		id: 'search_dialog_no_content_body',
		defaultMessage: 'Search for anything across your Atlassian products',
		description:
			'Body text of prompt to begin search journey if no recent content results are rendered, for non-Rovo users without AI enabled',
	},
});
