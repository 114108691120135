import React, { useCallback, useMemo, type FC } from 'react';

import {
	type LinkComponent,
	ReturnIcon,
	SquareReturnIcon,
	useKeyboardNavigation,
} from '@atlassian/search-dialog';
import SearchIcon from '@atlaskit/icon/core/migration/search';
import FilterIcon from '@atlaskit/icon/core/migration/filter';
import { CommandIcon, ControlIcon } from '../quick-find/icons';
import {
	AdvancedSearchLink,
	CenteredAdvancedSearchGroup,
	AdvancedSearchContent,
} from '../../common/advanced-search-link';
import { type ScreenSpecificProps } from '../product-router/product/result-provider/result-renderer';
import { useQuery } from '../query-context';
import { addQuery } from '../../utils/url-utils';
import {
	getTrigger,
	isNewTab,
	onAdvancedSearchSelected,
	useAnalytics,
} from '../../common/analytics';
import { type WrappedComponentProps, injectIntl } from 'react-intl-next';
import { messages } from '../messages';
import { type Product, useProductContext } from '../product-router';
import {
	ContentWrapper,
	SearchFooterLabel,
	SearchFooterLabelWrapper,
} from './content-wrapper.styled';
import { MultiWorkspaceAdvancedSearchFooter } from '../../common/advanced-search-link';
import { useSessionUserInput } from '../user-input-provider';
import { useDialogExpansionContext } from '../dialog-expansion-context';
import { useSearchSessionId } from '../../common/search-session-provider';
import { ProductKeys } from '../quick-find';

export interface AdvancedSearchFooterProps
	extends Pick<ScreenSpecificProps, 'urlGeneratorForNoResultsScreen'> {
	/**
	 * Label used for the search dialog footer component.
	 */
	searchFooterLabel?: string;
	/**
	 * Label with icon used for the search dialog footer component.
	 */
	searchFooterLabelWithIcon?: React.ReactNode;
	/**
	 * A custom link component. This prop is designed to allow a custom link
	 * component to be passed to customize advanced search footer links within the dialog footer.
	 */
	linkComponent?: LinkComponent;
	/**
	 * A boolean to indicate whether to show the square return icon or smaller default icon
	 */
	squareReturnIcon?: boolean;
	/**
	 * A boolean to indicate whether this component refers to the "filter by confluence space..." footer or not
	 */
	filterByProductFooter?: boolean;
}

const onKeyDown = (e: KeyboardEvent, target: HTMLElement) => {
	if (e.key === 'Enter') {
		e.preventDefault();

		// The ref here is the wrapper span however we simulate a click on the `ResultLinkComponent` that it wraps.
		(target.firstElementChild as HTMLElement)?.click?.();
	}
};

const onKeyDownWithShift = (e: KeyboardEvent, target: HTMLElement) => {
	if (e.key === 'Enter' && e.shiftKey) {
		e.preventDefault();

		// The ref here is the wrapper span however we simulate a click on the `ResultLinkComponent` that it wraps.
		(target.firstElementChild as HTMLElement)?.click?.();
	}
};

interface ContextProps {
	query: string;
	isLoading: boolean;
	activeProduct?: Product;
	closeDialog: () => void;
}

export const AdvancedSearchFooterStateless = ({
	query,
	isLoading,
	activeProduct,
	closeDialog,
	searchFooterLabel,
	searchFooterLabelWithIcon,
	intl,
	linkComponent,
	urlGeneratorForNoResultsScreen = () => '',
	squareReturnIcon = false,
	filterByProductFooter = false,
}: ContextProps & AdvancedSearchFooterProps & WrappedComponentProps) => {
	const searchSessionId = useSearchSessionId();
	const productName = activeProduct?.title;
	const productId = activeProduct?.id;
	const urlGenerator = activeProduct?.generateAdvancedSearchUrl || urlGeneratorForNoResultsScreen;
	const baseSearchUrl = filterByProductFooter
		? `${urlGenerator(query)}&product=confluence`
		: urlGenerator(query);
	const advancedSearchUrl =
		query && productId === ProductKeys.Confluence
			? addQuery(baseSearchUrl, 'search_id', searchSessionId)
			: baseSearchUrl;
	const workspaces = activeProduct?.workspaces;
	const showSearchOnEnter = !!activeProduct?.showSearchOnEnter;
	const actionSubjectId = `${productName}AdvancedSearchLink`;
	const [isKeyboardHighlighted, ref] = useKeyboardNavigation<HTMLSpanElement>({
		onKeydownCallback: showSearchOnEnter ? onKeyDownWithShift : onKeyDown,
	});
	const { resetSearchSession } = useSessionUserInput();
	const searchLabel =
		searchFooterLabel || intl.formatMessage(messages.extensible_advanced_search_footer);
	const advancedSearchContentLabel = searchFooterLabelWithIcon || searchLabel;
	const { fireAnalyticsEvent } = useAnalytics();
	const onAdvancedSearchClicked = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			resetSearchSession();
			if (filterByProductFooter) {
				closeDialog();
			}
			fireAnalyticsEvent(
				onAdvancedSearchSelected({
					trigger: getTrigger(e),
					actionSubjectId: actionSubjectId,
					isLoading,
					newTab: isNewTab(e),
				}),
			);
		},
		[
			fireAnalyticsEvent,
			isLoading,
			actionSubjectId,
			resetSearchSession,
			closeDialog,
			filterByProductFooter,
		],
	);

	const ModifierKeyIcon = useMemo(
		() => (window.navigator.userAgent.includes('Mac') ? CommandIcon : ControlIcon),
		[],
	);

	if (workspaces && workspaces?.length > 1) {
		const workspacesWithAdvancedSearchLinks = workspaces.map((workspace) => ({
			product: productName || '',
			content: workspace.name,
			key: workspace.name,
			advancedSearchUrl: activeProduct?.generateAdvancedSearchUrl?.(query, workspace.baseUrl) || '',
		}));

		return (
			<MultiWorkspaceAdvancedSearchFooter
				workspaces={workspacesWithAdvancedSearchLinks}
				linkComponent={linkComponent}
				isLoading={isLoading}
				onClick={(_, e) => {
					onAdvancedSearchSelected({
						actionSubjectId: actionSubjectId,
						trigger: getTrigger(e),
						isLoading,
						newTab: isNewTab(e),
					});
				}}
			/>
		);
	}

	return (
		<span
			ref={ref}
			onClick={onAdvancedSearchClicked}
			role="none"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ display: 'flex', height: '100%' }}
		>
			<AdvancedSearchLink
				href={advancedSearchUrl}
				isKeyboardHighlighted={isKeyboardHighlighted}
				linkComponent={linkComponent}
			>
				<ContentWrapper>
					<CenteredAdvancedSearchGroup>
						{filterByProductFooter ? (
							<FilterIcon color="currentColor" LEGACY_size="small" label={searchLabel} />
						) : (
							<SearchIcon color="currentColor" LEGACY_size="small" label={searchLabel} />
						)}
						<AdvancedSearchContent>{advancedSearchContentLabel}</AdvancedSearchContent>
					</CenteredAdvancedSearchGroup>
				</ContentWrapper>
				{showSearchOnEnter ? (
					<SearchFooterLabelWrapper>
						<SearchFooterLabel>Shift</SearchFooterLabel>
						+
						<ReturnIcon inverted={isKeyboardHighlighted} />
					</SearchFooterLabelWrapper>
				) : squareReturnIcon ? (
					<SearchFooterLabelWrapper>
						{filterByProductFooter && <ModifierKeyIcon />}
						<SquareReturnIcon />
					</SearchFooterLabelWrapper>
				) : (
					<>
						<ReturnIcon inverted={isKeyboardHighlighted} />
					</>
				)}
			</AdvancedSearchLink>
		</span>
	);
};

const AdvancedSearchFooter = ({ ...rest }: AdvancedSearchFooterProps) => {
	const { query, isLoading } = useQuery();
	const { setIsExpanded } = useDialogExpansionContext();
	const { getActiveProduct } = useProductContext();
	const activeProduct = getActiveProduct();

	const closeDialog = useCallback(() => {
		setIsExpanded(false);
	}, [setIsExpanded]);

	return (
		<AdvancedSearchFooterStatelessWithIntl
			{...rest}
			query={query}
			isLoading={isLoading}
			activeProduct={activeProduct}
			closeDialog={closeDialog}
		/>
	);
};

export const AdvancedSearchFooterStatelessWithIntl: FC<ContextProps & AdvancedSearchFooterProps> =
	injectIntl(AdvancedSearchFooterStateless);

export default AdvancedSearchFooter;
