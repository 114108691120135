import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	searchAcrossProducts: {
		id: 'search_dialog_search_across_products',
		defaultMessage: 'Search across products',
		description: 'Label for link to redirect users to search page',
	},
	// as part of Jira experiement milestone 1, we will instead redirect users to the advanced search page on Jira
	viewAllIssuesJira: {
		id: 'search_dialog_view_all_work_items_jira',
		defaultMessage: 'View all work items',
		description: 'Label for link to redirect users to Jira Advanced Search Issues page (NIN view)',
	},
});
