import React from 'react';

import { FormattedMessage, type MessageDescriptor } from 'react-intl-next';

import { messages } from './messages';

enum ExternalDocumentCategory {
	DOCUMENT,
	PRESENTATION,
	SPREADSHEET,
}

export const getDocumentSubtypeLabel = (subtype: ExternalDocumentCategory | string) => {
	const SUBTYPE_DOCUMENT = 'DOCUMENT';
	const SUBTYPE_PRESENTATION = 'PRESENTATION';
	const SUBTYPE_SPREADSHEET = 'SPREADSHEET';

	type DocumentSubtypeLabels = Record<string, MessageDescriptor>;

	// TODO: When providerIds are available, we'll use them to differentiate between document providers
	// https://softwareteams.atlassian.net/browse/SAI-17364

	const GOOGLE_LABELS: DocumentSubtypeLabels = {
		[SUBTYPE_DOCUMENT]: messages.googleDocsLabel,
		[SUBTYPE_PRESENTATION]: messages.googleSlidesLabel,
		[SUBTYPE_SPREADSHEET]: messages.googleSheetsLabel,
	};

	const normalizedSubtype = subtype.toString().toUpperCase();

	return <FormattedMessage {...(GOOGLE_LABELS[normalizedSubtype] || messages.driveLabel)} />;
};
