import {
	type AggResponse,
	type EntityATI,
	type ExperimentLayer,
	searchDialogQuery,
	type SearchPageData,
	type SearchPageNode,
	type SearchPageQueryVariables,
	type ThirdPartyFilters,
} from '@atlassian/search-client';

import { type PrimaryProductKey, ProductKeys3P } from '../../../../common/constants/products';
import { type ThirdPartyConfigsBootstrap } from '../../../../common/constants/schemas/3p-config';
import { type SelectedFiltersType } from '../../../../common/constants/types';
import {
	getEmptySelectedFilters,
	getIntegrationARIsFromConfig,
	getThirdPartyFilters,
} from '../../../../common/utils/filters';

import { userShardMock } from './mocks';

export const getThirdPartyFiltersForQuickFind = (thirdPartyConfigs: ThirdPartyConfigsBootstrap) => {
	const quickFind3PProducts = [ProductKeys3P.GoogleDrive];
	const quickFind3PEntities = quickFind3PProducts
		.map((productKey) => thirdPartyConfigs[productKey]?.typeFilterValues ?? [])
		.flat();
	const quickFindIntegrationARIs = getIntegrationARIsFromConfig();
	const titleMatchValue = false;
	return getThirdPartyFilters(
		quickFind3PProducts,
		titleMatchValue,
		quickFind3PEntities,
		thirdPartyConfigs,
		quickFindIntegrationARIs,
	);
};

export const fetchConfluenceEntities = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	experimentId: string | undefined,
	shadowExperimentId: string | undefined,
	experimentLayers: ExperimentLayer[] | undefined,
	selectedFilters: SelectedFiltersType | null,
	thirdPartyFilters: ThirdPartyFilters | undefined,
) => {
	// TODO: replace all the hard-coded values of filters with the ones passed on from selectedFilters
	// refer to fetchJiraEntities
	const { commonFilters, confluenceFilters } = selectedFilters || getEmptySelectedFilters();

	const variables = {
		experience: 'quick-find',
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 7,
		commonFilters,
		confluenceFilters,
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		mercuryFilters: {
			owners: [],
			focusAreaTypeIds: [],
		},
		thirdPartyFilters: thirdPartyFilters ?? { titleMatchOnly: true, subtypes: [] },
		entities: [
			'ati:cloud:confluence:page',
			'ati:cloud:confluence:blogpost',
			'ati:cloud:confluence:attachment',
			'ati:cloud:confluence:whiteboard',
			'ati:cloud:confluence:database',
			...(thirdPartyFilters ? ['ati:cloud:graph:document'] : []),
		] as EntityATI[],
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers,
	} satisfies SearchPageQueryVariables;

	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: response.extensions?.gateway?.request_id,
	};
};

export const fetchConfluenceSpaces = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	experimentId: string | undefined,
	shadowExperimentId: string | undefined,
	experimentLayers: ExperimentLayer[] | undefined,
	selectedFilters: SelectedFiltersType | null,
) => {
	// TODO: replace all the hard-coded values of filters with the ones passed on from selectedFilters
	// and remove experiementId and shadowExperimentId as these are no longer used
	// refer to fetchJiraEntities
	const { commonFilters, confluenceFilters } = selectedFilters || getEmptySelectedFilters();

	const variables = {
		experience: 'quick-find',
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 1,
		commonFilters,
		confluenceFilters,
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		mercuryFilters: {
			owners: [],
			focusAreaTypeIds: [],
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		entities: ['ati:cloud:confluence:space'] as EntityATI[],
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers,
	} satisfies SearchPageQueryVariables;

	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchSpacesRequestId: response.extensions?.gateway?.request_id,
	};
};

export const fetchAtlasEntities = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	experimentId: string | undefined,
	shadowExperimentId: string | undefined,
	experimentLayers: ExperimentLayer[] | undefined,
	selectedFilters: SelectedFiltersType | null,
) => {
	// TODO: replace all the hard-coded values of filters with the ones passed on from selectedFilters
	// and remove experiementId and shadowExperimentId as these are no longer used
	// refer to fetchJiraEntities
	const { commonFilters } = selectedFilters || getEmptySelectedFilters();

	const variables = {
		experience: 'quick-find',
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 6,
		commonFilters,
		confluenceFilters: {
			spacesFilter: [],
			contributorsFilter: [],
			ancestorIdsFilter: [],
			range: [],
			owners: [],
			labelsFilter: [],
			titleMatchOnly: true,
		},
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		mercuryFilters: {
			owners: [],
			focusAreaTypeIds: [],
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		entities: [
			'ati:cloud:townsquare:project',
			'ati:cloud:townsquare:goal',
			'ati:cloud:townsquare:tag',
		] as EntityATI[],
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers,
	} satisfies SearchPageQueryVariables;

	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});

	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: response.extensions?.gateway?.request_id,
	};
};

export const fetchMercuryEntities = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	experimentId: string | undefined,
	shadowExperimentId: string | undefined,
	experimentLayers: ExperimentLayer[] | undefined,
	selectedFilters: SelectedFiltersType | null,
) => {
	// TODO: replace all the hard-coded values of filters with the ones passed on from selectedFilters
	// and remove experiementId and shadowExperimentId as these are no longer used
	// refer to fetchJiraEntities
	const { commonFilters } = selectedFilters || getEmptySelectedFilters();

	const variables = {
		experience: 'quick-find',
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 6,
		commonFilters: commonFilters,
		confluenceFilters: {
			spacesFilter: [],
			contributorsFilter: [],
			ancestorIdsFilter: [],
			range: [],
			owners: [],
			labelsFilter: [],
			titleMatchOnly: true,
		},
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		mercuryFilters: {
			owners: [],
			focusAreaTypeIds: [],
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		entities: ['ati:cloud:mercury:focus-area'] as EntityATI[],
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers,
	} satisfies SearchPageQueryVariables;

	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});

	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: response.extensions?.gateway?.request_id,
	};
};

export const fetchJiraEntities = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	experimentLayers: ExperimentLayer[] | undefined,
	selectedFilters: SelectedFiltersType | null,
) => {
	const { commonFilters, confluenceFilters, mercuryFilters, thirdPartyFilters, jiraFilters } =
		selectedFilters || getEmptySelectedFilters();
	const variables = {
		experience: 'quick-find',
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:jira::site/${cloudId}`,
		tenantId: cloudId,
		limit: 7,
		commonFilters,
		confluenceFilters,
		jiraFilters,
		mercuryFilters,
		thirdPartyFilters,
		entities: [
			'ati:cloud:jira:board',
			'ati:cloud:jira:dashboard',
			'ati:cloud:jira:filter',
			'ati:cloud:jira:issue',
			'ati:cloud:jira:project',
		] as EntityATI[],
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentLayers,
	} satisfies SearchPageQueryVariables;
	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: response.extensions?.gateway?.request_id,
	};
};

export const mapEssentialData = (result: SearchPageNode) => ({
	id: result.id,
	title: result.title,
	data: result,
	type: 'search-result' as const,
});

export const fetchConfluenceAndGoogleUserShardMock = async () => {
	const response = (await Promise.resolve(userShardMock)) as unknown as AggResponse<SearchPageData>;
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];

	return {
		results: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: undefined,
		fetchSpacesRequestId: undefined,
	};
};
