import { useMemo, useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import { useIntl } from 'react-intl-next';

import {
	type ConfluenceRecentsClientConfig,
	type ConfluenceSearchClientConfig,
	ConfluenceScope,
	CONFLUENCE_PRODUCT_ID,
} from './types';
import { FilterOptionSource } from '../../../common/filters/types';
import { ConfluenceItemResultRenderer, sectionTitleRenderer } from './title-and-result-renderer';
import type {
	ConfluenceGraphQLSpaceNode,
	ConfluenceGraphQLContentNode,
} from '@atlassian/search-client';
import { messages } from '../../../messages';
import { useRecentsClient } from './recents-client';
import type { GenericFilterOption, FilterStore, SpaceFilterOption } from '../../filters';
import { useQuery } from '../../query-context';
import { useProductContext } from '../../product-router';
import type { PostQuerySupplierArgs, SearchResult } from '../../product-router';
import { defaultFormatDate } from './utils';
import { GenericConfluenceIconRenderer, getAvatarForConfluenceObjectResult } from './result-icons';
import { transformAriToId } from '../utils/id-transformer';
import type { OptionData } from '@atlaskit/smart-user-picker';
import { type RendererOverrideTypes } from '../confluence/types';

const RECENT_ITEMS_SIZE_LIMIT = 10;
const RECENT_SPACES_SIZE_LIMIT = 3;
const ITEM_LIMIT_SIZE = 10;
const SPACE_LIMIT_SIZE = 3;
const PEOPLE_LIMIT_SIZE = 3;

type confluenceSuppliersArgType = {
	recentClientConfig: ConfluenceRecentsClientConfig;
	searchClientConfig?: ConfluenceSearchClientConfig;
	spaceFilterStore: FilterStore<SpaceFilterOption>;
	peopleFilterStore: FilterStore<GenericFilterOption>;
	isExpanded: boolean;
	isUserAnonymous: boolean;
	formatDate?: (lastModified: string) => ReactElement;
	autocorrectEnabled?: boolean;
	rendererOverride?: RendererOverrideTypes;
	additionalContentTypesEnabled: boolean;
};

export const useConfluenceSuppliers = ({
	recentClientConfig,
	searchClientConfig,
	spaceFilterStore,
	peopleFilterStore,
	isExpanded,
	isUserAnonymous,
	formatDate,
	autocorrectEnabled,
	rendererOverride,
	additionalContentTypesEnabled,
}: confluenceSuppliersArgType) => {
	const { query } = useQuery();
	const { getActiveProduct } = useProductContext();
	const warmUpCache =
		isExpanded && query !== '' && getActiveProduct()?.id === CONFLUENCE_PRODUCT_ID;
	const [hasFetchedSpaces, setHasFetchedSpaces] = useState(
		spaceFilterStore?.availableFilters?.length > 0 || false,
	);
	const [hasFetchedUsers, setHasFetchedUsers] = useState(
		peopleFilterStore?.availableFilters?.length > 0 || false,
	);
	const intl = useIntl();
	const { isCollaborationGraphEnabled } = recentClientConfig;
	const { getRecentItems, getRecentSpaces, getRecentPeople, getCollabGraphContainers } =
		useRecentsClient(recentClientConfig);

	// Set the default space filters to come from the collab graph if they are not enabled for recents
	useEffect(() => {
		if (warmUpCache && !isCollaborationGraphEnabled && !hasFetchedSpaces) {
			getCollabGraphContainers().then((spaces) => {
				if (spaces.items.length > 0) {
					spaceFilterStore.setDefaultFilters(
						spaces.items.map((item, index) => ({
							id: item.id,
							spaceKey: item.key,
							avatarUrl: item.avatarUrl,
							label: item.name,
							filterSource: FilterOptionSource.RECENT,
							isChecked: false,
							isVisible: index < 3,
						})),
					);
				}
				setHasFetchedSpaces(true);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCollaborationGraphEnabled, getCollabGraphContainers, isExpanded, warmUpCache]);

	useEffect(() => {
		if (warmUpCache && !hasFetchedUsers) {
			getRecentPeople().then((recentPeople) => {
				peopleFilterStore.setDefaultFilters(
					recentPeople.items.map((item, index) => ({
						id: item.userId,
						avatarUrl: item.avatarUrl,
						label: item.name,
						filterSource: FilterOptionSource.RECENT,
						isChecked: false,
						isVisible: index < 3,
					})),
				);
			});
			setHasFetchedUsers(true);
		}
	}, [warmUpCache, peopleFilterStore, getRecentPeople, hasFetchedUsers]);

	const contentResultRenderer =
		additionalContentTypesEnabled && rendererOverride?.ConfluenceResultRenderer
			? rendererOverride.ConfluenceResultRenderer
			: ConfluenceItemResultRenderer;

	const scope =
		additionalContentTypesEnabled && rendererOverride?.contentScope
			? rendererOverride.contentScope
			: ConfluenceScope.Item;

	const preQueryItemSupplier = useMemo(
		() => () =>
			Promise.all([getRecentItems(), getRecentSpaces()]).then((results) => {
				const recentItems = results[0].items;
				const recentSpaces = results[1].items;

				if (!hasFetchedSpaces && isCollaborationGraphEnabled) {
					spaceFilterStore.setDefaultFilters(
						recentSpaces.map((item, index) => ({
							id: item.id,
							spaceKey: item.key,
							avatarUrl: item.avatarUrl,
							label: item.name,
							filterSource: FilterOptionSource.RECENT,
							isChecked: false,
							isVisible: index < 3,
						})),
					);
					setHasFetchedSpaces(true);
				}

				return {
					size: results.reduce((sum, { items }) => {
						return sum + items.length;
					}, 0),
					sections: [
						{
							id: 'recent-confluence-items',
							titleRenderer: sectionTitleRenderer(!!autocorrectEnabled),
							title: intl.formatMessage(messages.common_recently_viewed_section_heading),
							resultRenderer: contentResultRenderer,
							scope,
							searchResults: recentItems.map((result) => ({
								id: result.resultId,
								title: result.name,
								meta: result.containerName,
								additionalMeta: result.contentType,
								url: result.href,
								containerId: result.spaceId,
								icon: getAvatarForConfluenceObjectResult({
									type: result.contentType,
									title: result.name,
									isCollapsed: false,
								}),
								isRecentResult: true,
							})),
							resultLimit: RECENT_ITEMS_SIZE_LIMIT,
						},
						{
							id: 'recent-confluence-spaces',
							title: intl.formatMessage(messages.common_recently_spaces_section_heading),
							searchResults: recentSpaces.map((result) => ({
								id: result.resultId,
								title: result.name,
								meta: '',
								url: result.href,
								containerId: '',
								iconUrl: result.avatarUrl,
								isRecentResult: true,
							})),
							resultLimit: RECENT_SPACES_SIZE_LIMIT,
						},
					],
				};
			}),
		[
			getRecentItems,
			getRecentSpaces,
			hasFetchedSpaces,
			isCollaborationGraphEnabled,
			autocorrectEnabled,
			intl,
			spaceFilterStore,
			contentResultRenderer,
			scope,
		],
	);

	const postQueryItemSupplier = useMemo(
		() =>
			!searchClientConfig?.graphQLEnabled
				? undefined
				: async (args: PostQuerySupplierArgs) => {
						const { hasCheckedFilters, getUserSearchResults } = await import(
							'@atlassian/search-client'
						);

						const pageBlogAttachmentGraphQLResultMapper = ({
							node: item,
						}: {
							node?: ConfluenceGraphQLContentNode | null;
						}): SearchResult => {
							return {
								id: transformAriToId(item?.id || '', item?.type || ''),
								title: item?.title || '',
								url: item?.url || '',
								meta: item?.space?.name || '',
								additionalMeta: item?.lastModified
									? formatDate
										? formatDate(item?.lastModified)
										: defaultFormatDate(item?.lastModified)
									: '',
								containerId: transformAriToId(item?.space?.id || 'UNAVAILABLE', 'space'),
								type: item?.type || '',
								icon: getAvatarForConfluenceObjectResult({
									type: item?.type || '',
									title: item?.title || '',
									iconCssClass: item?.iconCssClass || '',
									isCollapsed: true,
								}),
							};
						};

						const spaceGraphQLResultMapper = ({
							node: item,
						}: {
							node?: ConfluenceGraphQLSpaceNode | null;
						}): SearchResult => {
							return {
								id: transformAriToId(item?.id || '', item?.type || ''),
								title: item?.title || '',
								meta: '',
								url: item?.url || '',
								type: item?.type || '',
								containerId: transformAriToId(item?.id || '', item?.type || ''),
								icon: GenericConfluenceIconRenderer(`/wiki${item?.iconPath || ''}`, true),
							};
						};

						const peopleResultMapper = (user: OptionData): SearchResult => ({
							title: user.name,
							id: user.id,
							meta: '',
							url: `${window.location.origin}/wiki/people/${user.id}`,
							icon: GenericConfluenceIconRenderer(`${user.avatarUrl}`, true),
						});
						const fetchOnlyPageBlogAttachments = hasCheckedFilters(args.filters);
						const graphQLResultsPromise = searchClientConfig?.graphQLPromise?.(args);
						const peopleResultsPromise =
							fetchOnlyPageBlogAttachments || isUserAnonymous
								? Promise.resolve([])
								: getUserSearchResults({
										query: args.query,
										cloudId: searchClientConfig?.cloudId || '',
										principalId: searchClientConfig?.userId,
										intl,
									}).catch((e) => []);

						return Promise.all([graphQLResultsPromise, peopleResultsPromise]).then((results) => {
							const pageBlogAttachmentResults =
								results[0]?.search.pageBlogAttachments.edges.map(
									pageBlogAttachmentGraphQLResultMapper,
								) || [];
							const pageBlogAttachmentsCount = results[0]?.search.pageBlogAttachments.totalCount;
							const spaceResults =
								results[0]?.search.spaces?.edges.map(spaceGraphQLResultMapper) || [];
							const peopleResults = results[1].map(peopleResultMapper) || [];
							return {
								sections: [
									{
										id: scope,
										title: intl.formatMessage(messages.confluence_content_section_heading),
										searchResults: pageBlogAttachmentResults,
										titleRenderer: sectionTitleRenderer(!!autocorrectEnabled),
										resultRenderer: contentResultRenderer,
										showResultCount: true,
										size: pageBlogAttachmentsCount || undefined,
										resultLimit: ITEM_LIMIT_SIZE,
									},
									{
										id: ConfluenceScope.Space,
										title: intl.formatMessage(messages.confluence_spaces_section_heading),
										searchResults: spaceResults,
										resultLimit: SPACE_LIMIT_SIZE,
									},
									{
										id: ConfluenceScope.People,
										title: intl.formatMessage(messages.confluence_people_section_heading),
										searchResults: peopleResults,
										resultLimit: PEOPLE_LIMIT_SIZE,
									},
								],
								size: pageBlogAttachmentResults.length + spaceResults.length + peopleResults.length,
							};
						});
					},
		[
			autocorrectEnabled,
			formatDate,
			intl,
			searchClientConfig,
			contentResultRenderer,
			isUserAnonymous,
			scope,
		],
	);

	return {
		preQueryItemSupplier,
		postQueryItemSupplier,
	};
};
