import { type IntlShape } from 'react-intl-next';
import { messages } from './messages';
import { type SearchResult } from '../../product-router/product/result-types';
import { type AggregatorSection } from '../../result-supplier/default-suppliers';
import {
	type AggregatorCompassResponse,
	type CompassComponentResponse,
	CompassScope,
} from './types';
import { componentResultRenderer } from './result-renderer';

const mapAggregatorResponseToSearchItem = (response: CompassComponentResponse): SearchResult => ({
	title: response.title,
	id: response.id,
	meta: response.ownerId,
	url: response.url,
	icon: response.type,
	containerId: '', // no container
});

export const getSections = (
	intl: IntlShape,
): AggregatorSection<CompassScope, AggregatorCompassResponse>[] => [
	{
		id: CompassScope.Component,
		title: intl.formatMessage(messages.compass_components_section_heading),
		scope: CompassScope.Component,
		showResultCount: true,
		resultRenderer: componentResultRenderer,
		resultMapper: ({ results }: AggregatorCompassResponse): SearchResult[] =>
			results.map((value) => mapAggregatorResponseToSearchItem(value)),
	},
];
