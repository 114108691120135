import React, { Fragment } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Avatar from '@atlaskit/avatar';

import IssueIcon from '@atlaskit/icon/core/issue';
import QuotationMarkIcon from '@atlaskit/icon/core/quotation-mark';
import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import FolderClosedIcon from '@atlaskit/icon/core/folder-closed';
import CommentIcon from '@atlaskit/icon/core/comment';
import DatabaseIcon from '@atlaskit/icon/core/database';

import PageIcon from '@atlaskit/icon/core/page';
import { AttachmentIcon } from '@atlassian/search-common';
import { IconTile } from '@atlaskit/icon';

type ContentIconRendererArgs = {
	type: string;
	title?: string | null | undefined;
	iconCssClass?: string;
	imageSrc?: string;
	isDraft?: boolean;
};

type GenericIconRendererArgs = {
	iconUrl: string;
	size?: 'small' | 'xsmall';
	appearance?: 'square' | 'circle';
};

const negativeMarginStyles = xcss({
	margin: 'space.negative.025',
	display: 'flex',
});

const iconTypes = {
	ATLAS: 'atlas',
	ATTACHMENT: 'attachment',
	BLOGPOST: 'blogpost',
	COMMENT: 'comment',
	CONTENT: 'content',
	CONFLUENCEBLOGPOST: 'confluence-blogpost',
	CONFLUENCEPAGE: 'confluence-page',
	DATABASE: 'database',
	FOLDER: 'folder',
	JIRA: 'jira',
	PAGE: 'page',
	WHITEBOARD: 'whiteboard',
} as const;

const iconStyles = {
	BLUE: 'blue',
	GRAY: 'gray',
	PURPLE: 'purple',
	SHAPE: 'square',
	SIZE: '24',
	TEAL: 'teal',
	YELLOW: 'yellow',
} as const;

// TODO: Figure out type for smartlinks
export const ContentIconRenderer = ({
	type,
	imageSrc,
	title,
	isDraft = false,
}: ContentIconRendererArgs) => {
	// CONFLUENCE
	if (type === iconTypes.PAGE || type === iconTypes.CONFLUENCEPAGE || type === iconTypes.CONTENT) {
		return (
			<IconTile
				icon={PageIcon}
				label={type}
				appearance={isDraft ? iconStyles.GRAY : iconStyles.BLUE}
				shape={iconStyles.SHAPE}
				size={iconStyles.SIZE}
			/>
		);
	} else if (type === iconTypes.BLOGPOST || type === iconTypes.CONFLUENCEBLOGPOST) {
		return (
			<IconTile
				icon={QuotationMarkIcon}
				label={type}
				appearance={iconStyles.BLUE}
				shape={iconStyles.SHAPE}
				size={iconStyles.SIZE}
			/>
		);
	} else if (type === iconTypes.DATABASE) {
		return (
			<IconTile
				icon={DatabaseIcon}
				label={type}
				appearance={iconStyles.PURPLE}
				shape={iconStyles.SHAPE}
				size={iconStyles.SIZE}
			/>
		);
	} else if (type === iconTypes.FOLDER) {
		return (
			<IconTile
				icon={FolderClosedIcon}
				label={type}
				appearance={iconStyles.BLUE}
				shape={iconStyles.SHAPE}
				size={iconStyles.SIZE}
			/>
		);
	} else if (type === iconTypes.WHITEBOARD) {
		return (
			<IconTile
				icon={WhiteboardIcon}
				label={type}
				appearance={iconStyles.TEAL}
				shape={iconStyles.SHAPE}
				size={iconStyles.SIZE}
			/>
		);
	} else if (type === iconTypes.COMMENT) {
		return (
			<IconTile
				icon={CommentIcon}
				label={type}
				appearance={iconStyles.YELLOW}
				shape={iconStyles.SHAPE}
				size={iconStyles.SIZE}
			/>
		);
	} else if (type === iconTypes.ATTACHMENT) {
		// TODO: Clarify if the attachment icon should be changed
		return <AttachmentIcon title={title || ''} size="medium" label={type} />;
	} else if (type === iconTypes.ATLAS) {
		return <img width="24px" height="24px" src={imageSrc || ''} aria-label={type} alt={type} />;
	} else if (type === iconTypes.JIRA) {
		// TODO: Clarify the decision made for jira icons
		return (
			<IconTile
				icon={IssueIcon}
				label={type}
				appearance={iconStyles.BLUE}
				shape={iconStyles.SHAPE}
				size={iconStyles.SIZE}
			/>
		);
	}
	return <Fragment />;
};

export const GenericIconRenderer = ({
	iconUrl,
	size = 'small',
	appearance = 'square',
}: GenericIconRendererArgs) => (
	<Box xcss={negativeMarginStyles}>
		<Avatar
			size={size}
			borderColor="transparent"
			src={iconUrl}
			appearance={appearance}
			testId="result-avatar"
		/>
	</Box>
);
