import React, { type PropsWithChildren, type RefObject, useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import FilterIcon from '@atlaskit/icon/core/filter';
import { Inline } from '@atlaskit/primitives';

import type { SelectFilterOption } from '../../../../../common/constants/filters/select-filter/types';
import { ReturnIcon } from '../../../../../common/constants/icons/return';
import { SearchResult } from '../../../../../common/ui/quick-find/search-dialog-result';
import { onQueryFilterSelected } from '../../../../../common/utils/quick-find/events/query-filter-selected';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../../../controllers/quick-find/analytics';
import { getInputQueryWithNewSelectedQueryFilter } from '../../../../../controllers/quick-find/query-filters/utils';
import {
	useCurrentQueryFilter,
	useQuickFindActions,
} from '../../../../../controllers/store/quick-find';

import { messages } from './messages';

type SelectFilterOptionProps = {
	title: React.ReactNode;
	option: SelectFilterOption;
	setOption: (option: SelectFilterOption | SelectFilterOption[]) => void | Promise<void>;
	localQuery: string;
	setLocalQuery: React.Dispatch<React.SetStateAction<string>>;
	inputRef?: RefObject<HTMLInputElement>;
};

export const Option = ({
	title,
	option,
	setOption,
	localQuery,
	setLocalQuery,
	inputRef,
}: PropsWithChildren<SelectFilterOptionProps>) => {
	const intl = useIntl();

	const currentQueryFilter = useCurrentQueryFilter();

	const { addSelectedQueryFilter } = useQuickFindActions();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	const icon = <FilterIcon label={intl.formatMessage(messages.filterIconLabel)} />;

	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			<ReturnIcon />
		</Inline>
	);

	const onResultClicked = useCallback(() => {
		if (!currentQueryFilter) {
			return;
		}

		// Set the filter
		setOption({ ...option, isSelected: false });

		// Update the query shown in the input
		const newLocalQuery = getInputQueryWithNewSelectedQueryFilter(
			localQuery,
			currentQueryFilter?.keyword,
			option.label,
		);
		setLocalQuery(newLocalQuery);

		// Update the selected query filters
		addSelectedQueryFilter({
			filterId: currentQueryFilter.filterId,
			keyword: currentQueryFilter.keyword,
			displayText: currentQueryFilter.keyword + option.label,
			option,
		});

		// Fire analytics event
		fireAnalyticsEvent(
			onQueryFilterSelected({
				actionSubjectId: currentQueryFilter.filterId,
				attributes: commonAttributes,
				nonPrivacySafeAttributes,
			}),
		);

		inputRef?.current?.focus();
	}, [
		addSelectedQueryFilter,
		commonAttributes,
		currentQueryFilter,
		fireAnalyticsEvent,
		inputRef,
		localQuery,
		nonPrivacySafeAttributes,
		option,
		setLocalQuery,
		setOption,
	]);

	return (
		<SearchResult
			id={option.value}
			icon={icon}
			title={title}
			onResultClicked={onResultClicked}
			highlightedRightMetadata={highlightedRightMetadata}
		/>
	);
};
