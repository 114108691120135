import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	googleDocsLabel: {
		id: 'document-search-result.google-docs-label',
		defaultMessage: 'Docs',
		description: 'Google Docs subtype label',
	},
	googleSlidesLabel: {
		id: 'document-search-result.google-slides-label',
		defaultMessage: 'Slides',
		description: 'Google Slides subtype label',
	},
	googleSheetsLabel: {
		id: 'document-search-result.google-sheets-label',
		defaultMessage: 'Sheets',
		description: 'Google Sheets subtype label',
	},
	driveLabel: {
		id: 'document-search-result.drive-label',
		defaultMessage: 'Drive',
		description: 'Default subtype label for drive documents when no subtype is available',
	},
});
