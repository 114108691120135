import { type ReactElement, type ReactNode, useCallback } from 'react';

import type ApolloClient from 'apollo-client';
import { useIntl } from 'react-intl-next';

import { type ExperimentLayer } from '../../../common/types';

import {
	type QuickFindSpacesQuery_myVisitedSpaces_nodes_spaces as QuickFindSearchSpaceType,
	type QuickFindSpacesQuery as QuickFindSpacesQueryType,
	type QuickFindSpacesQueryVariables,
} from './__types__/QuickFindSpacesQuery';
import { QuickFindSpacesQuery } from './QuickFindSpacesQuery.graphql';
import { recentActivitiesQuery } from './recent-activities';
import { type ActivityItemEdge } from './recent-activities/types';
import { quickFindSearchQuery } from './search';
import { getUserAndTeamSearchResults } from './user-supplier';
import { parsePartialApolloResults } from './utils';

const INCLUDED_PRODUCTS = ['confluence', 'jira', 'townsquare'];

// To filter out Confluence comment results - although query does
// not include comment type it is still returning comment results
const INCLUDED_OBJECT_TYPES = [
	'page',
	'blogpost',
	'whiteboard',
	'issue',
	'project',
	'goal',
	'content',
];
const PRE_QUERY_MAX_RECENT_CONTENTS_FETCHED = 200;
const PRE_QUERY_MAX_RECENT_SPACES_FETCHED = 10;
const PRE_QUERY_MAX_RECENT_CONTENTS_DISPLAYED = 7;
const PRE_QUERY_MAX_RECENT_SPACES_DISPLAYED = 3;

const POST_QUERY_MAX_CONTENTS = 7;
const POST_QUERY_MAX_SPACES = 1;

export type QuickFindSearchResultType = {
	ari?: string;
	id: string;
	title: string;
	meta: string | ReactElement;
	additionalMeta?: string | ReactElement;
	url: string;
	containerId?: string;
	icon?: string | ReactElement;
	label?: ReactNode;
	isRecentResult?: boolean;
	type?: string;
};

type ItemRenderer = ({
	searchResult,
	isKeyboardHighlighted,
}: {
	searchResult: QuickFindSearchResultType;
	isKeyboardHighlighted: boolean;
}) => JSX.Element;

type QuickFindItemSupplierArgs = {
	/** Supplier expects confCloudClient which proxies to cc-graphql, invokes queries of activity feed and then transforms the results so that the Search Sections can be rendered.... */
	confCloudClient?: Pick<ApolloClient<object>, 'query'>;
	/** Consumers should throw errors on request failure, implementation must include error boundary in a parent component for errors to propogate */
	markErrorAsHandled?: (error: any) => void;
	itemRenderer: ItemRenderer;
	recentContentResultMapper: (edge: ActivityItemEdge) => QuickFindSearchResultType;
	recentSpaceResultMapper: (item: QuickFindSearchSpaceType | null) => QuickFindSearchResultType;
	userId?: string;
	aggAbsoluteUrl?: string;
	cloudId: string;
};

export const RECENT_ITEMS_SECTION_ID = 'recent-items';
export const RECENT_SPACES_SECTION_ID = 'recent-spaces';

// TODO: split pre- and post-query suppliers into seperate files, include 'pre-query' in the name
export const useQuickFindItemSupplier = ({
	confCloudClient,
	aggAbsoluteUrl,
	markErrorAsHandled,
	itemRenderer,
	recentContentResultMapper,
	recentSpaceResultMapper,
	userId,
	cloudId,
}: QuickFindItemSupplierArgs) => {
	return useCallback(async () => {
		const getSections = ({
			recentContents,
			recentSpaces,
		}: {
			recentContents: QuickFindSearchResultType[];
			recentSpaces: QuickFindSearchResultType[];
		}) => ({
			sections: [
				{
					id: RECENT_ITEMS_SECTION_ID,
					title: '',
					searchResults: recentContents,
					resultLimit: PRE_QUERY_MAX_RECENT_CONTENTS_DISPLAYED,
					resultRenderer: itemRenderer,
				},
				{
					id: RECENT_SPACES_SECTION_ID,
					title: '',
					searchResults: recentSpaces,
					resultLimit: PRE_QUERY_MAX_RECENT_SPACES_DISPLAYED,
					resultRenderer: itemRenderer,
				},
			],
		});

		if (!userId) {
			return getSections({ recentContents: [], recentSpaces: [] });
		}

		const recentActivitiesPromise = recentActivitiesQuery({
			variables: {
				filteredProducts: INCLUDED_PRODUCTS,
				filteredObjectTypes: INCLUDED_OBJECT_TYPES,
				filteredRootContainerIds: [
					`ari:cloud:platform::site/${cloudId}`, // covers Confluence, Jira
					`ari:cloud:townsquare::site/${cloudId}`, // for some reason Atlas has a different root container id
				],
				itemsLimit: PRE_QUERY_MAX_RECENT_CONTENTS_FETCHED,
			},
			aggAbsoluteUrl,
		});

		const recentSpacesPromise = confCloudClient
			? confCloudClient.query<QuickFindSpacesQueryType, QuickFindSpacesQueryVariables>({
					query: QuickFindSpacesQuery,
					variables: {
						spaceLimit: PRE_QUERY_MAX_RECENT_SPACES_FETCHED,
					},
				})
			: Promise.resolve(undefined);

		const { recentContents, recentSpaces } = await Promise.all([
			recentActivitiesPromise,
			recentSpacesPromise,
		])
			.then(([recentItemsResponse, recentSpacesResponse]) => {
				const recentContents =
					recentItemsResponse?.data?.activity?.myActivity?.all.edges
						.filter((edge: ActivityItemEdge) => edge?.node?.object?.data)
						.map(recentContentResultMapper) || [];

				const recentSpacesResults = parsePartialApolloResults<
					QuickFindSpacesQueryType['myVisitedSpaces'] | undefined
				>(recentSpacesResponse?.data?.myVisitedSpaces, recentSpacesResponse?.errors);
				const recentSpaces = recentSpacesResults?.nodes?.spaces?.map(recentSpaceResultMapper) || [];

				return {
					recentContents,
					recentSpaces,
				};
			})
			.catch((err) => {
				markErrorAsHandled && markErrorAsHandled(err);
				throw err;
			});

		return getSections({ recentContents, recentSpaces });
	}, [
		confCloudClient,
		userId,
		markErrorAsHandled,
		itemRenderer,
		recentContentResultMapper,
		recentSpaceResultMapper,
		aggAbsoluteUrl,
		cloudId,
	]);
};

const CONFLUENCE_CONTENT_ENTITIES = [
	'ati:cloud:confluence:page',
	'ati:cloud:confluence:blogpost',
	'ati:cloud:confluence:attachment',
	'ati:cloud:confluence:whiteboard',
	'ati:cloud:confluence:database',
];

const ATLAS_ENTITIES = [
	'ati:cloud:townsquare:project',
	'ati:cloud:townsquare:goal',
	'ati:cloud:townsquare:tag',
];

const ATLAS_PROJECT_GOAL_TAG_SECTION_ID = 'townsquare.project,goal,tag';

export const CONFLUENCE_PAGE_BLOGPOST_ATTACHMENT_SECTION_ID = 'confluence.page,blogpost,attachment';
export const CONFLUENCE_SPACE_SECTION_ID = 'space';
export const PEOPLE_AND_TEAMS_SECTION_ID = 'people-and-teams';

const ProductKeys = {
	Confluence: 'confluence',
	Atlas: 'townsquare',
} as const;

type ProductKey = (typeof ProductKeys)[keyof typeof ProductKeys];

type usePostQueryItemSupplierArgs = {
	aggAbsoluteUrl?: string;
	cloudId: string;
	confluenceResultMapper: (result: any) => QuickFindSearchResultType;
	atlasResultMapper: (result: any) => QuickFindSearchResultType;
	spaceResultMapper: (result: any) => QuickFindSearchResultType;
	peopleAndTeamsResultMapper: (result: any) => QuickFindSearchResultType;
	resultRenderer: ItemRenderer;
	sectionTitle: string;
	userId?: string | null;
	orgId?: string | null;
	primaryProduct: ProductKey;
	experimentId?: string;
	shadowExperimentId?: string;
	experimentLayers?: ExperimentLayer[];
};

export const useQuickFindPostQueryItemSupplier = ({
	aggAbsoluteUrl,
	cloudId,
	resultRenderer,
	confluenceResultMapper,
	atlasResultMapper,
	spaceResultMapper,
	peopleAndTeamsResultMapper,
	sectionTitle,
	userId,
	orgId,
	primaryProduct,
	experimentId,
	shadowExperimentId,
	experimentLayers,
}: usePostQueryItemSupplierArgs) => {
	const intl = useIntl();

	return useCallback(
		async (args: any) => {
			const { query, analyticsInfo } = args;

			const getPeopleAndTeamsResults = async (
				query: string,
				userId?: string | null,
				cloudId?: string,
			) => {
				if (!userId || !cloudId) {
					return [];
				}

				const peopleResults = await getUserAndTeamSearchResults({
					query,
					cloudId: cloudId!,
					principalId: userId || '',
					orgId: orgId || '',
					intl,
				}).catch((e) => {
					console.error(e);
					return [];
				});
				return peopleResults.map(peopleAndTeamsResultMapper);
			};

			const getConfluenceResults = async (query: string, cloudId: string) => {
				const contentQueryResults = await quickFindSearchQuery({
					variables: {
						query,
						cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
						resultsLimit: POST_QUERY_MAX_CONTENTS,
						spaceLimit: POST_QUERY_MAX_SPACES,
						includeAncestors: true, // to show breadcrumbs
						contentEntities: CONFLUENCE_CONTENT_ENTITIES,
						confluenceFilters: { titleMatchOnly: true },
						searchSessionId: analyticsInfo?.searchSessionId,
						queryVersion: analyticsInfo?.queryVersion,
						includeSpaces: true,
						experimentId,
						shadowExperimentId,
						experimentLayers,
					},
					aggAbsoluteUrl,
				});
				const contentResponse = contentQueryResults.data?.search.results?.edges || [];
				const contentResults = contentResponse.map(confluenceResultMapper);
				const spaceResponse = contentQueryResults.data?.search.spaces?.edges || [];
				const spaceResults = spaceResponse.map(spaceResultMapper);
				return { contentResults, spaceResults };
			};

			const getAtlasResults = async (
				query: string,
				analyticsInfo?: { queryVersion: number; searchSessionId: string },
			) => {
				const contentQueryResults = await quickFindSearchQuery({
					variables: {
						query,
						cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
						contentEntities: ATLAS_ENTITIES,
						confluenceFilters: { titleMatchOnly: true }, // this filter is applied on Atlas results as well
						resultsLimit: POST_QUERY_MAX_CONTENTS,
						spaceLimit: POST_QUERY_MAX_SPACES,
						includeAncestors: false,
						searchSessionId: analyticsInfo?.searchSessionId,
						queryVersion: analyticsInfo?.queryVersion,
						includeSpaces: false,
						experimentId,
						shadowExperimentId,
						experimentLayers,
					},
					aggAbsoluteUrl,
				});
				const contentResponse = contentQueryResults.data?.search.results?.edges || [];
				const contentResults = contentResponse.map(atlasResultMapper);
				return { contentResults, spaceResults: [] };
			};

			const getResults = (product: ProductKey) => {
				switch (product) {
					case ProductKeys.Confluence:
						return getConfluenceResults(query, cloudId);
					case ProductKeys.Atlas:
						return getAtlasResults(query, analyticsInfo);
					default:
						return getConfluenceResults(query, cloudId);
				}
			};

			const { contentResults, spaceResults } = await getResults(primaryProduct);
			const peopleAndTeamsResults = await getPeopleAndTeamsResults(query, userId, cloudId);

			const getContentSectionId = (product: ProductKey) => {
				switch (product) {
					case ProductKeys.Confluence:
						return CONFLUENCE_PAGE_BLOGPOST_ATTACHMENT_SECTION_ID;
					case ProductKeys.Atlas:
						return ATLAS_PROJECT_GOAL_TAG_SECTION_ID;
					default:
						return CONFLUENCE_PAGE_BLOGPOST_ATTACHMENT_SECTION_ID;
				}
			};

			return {
				sections: [
					{
						id: getContentSectionId(primaryProduct),
						title: sectionTitle,
						searchResults: contentResults,
						resultRenderer,
					},
					{
						id: CONFLUENCE_SPACE_SECTION_ID,
						searchResults: spaceResults,
						title: !contentResults.length ? sectionTitle : '',
						resultRenderer,
					},
					{
						id: PEOPLE_AND_TEAMS_SECTION_ID,
						searchResults: peopleAndTeamsResults,
						title: !contentResults.length && !spaceResults.length ? sectionTitle : '',
						resultRenderer,
					},
				],
			};
		},
		[
			cloudId,
			resultRenderer,
			confluenceResultMapper,
			atlasResultMapper,
			spaceResultMapper,
			peopleAndTeamsResultMapper,
			sectionTitle,
			intl,
			orgId,
			userId,
			aggAbsoluteUrl,
			primaryProduct,
			experimentId,
			shadowExperimentId,
			experimentLayers,
		],
	);
};
