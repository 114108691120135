import React from 'react';

import { ProductStates } from '../../product-state-machine/product-state-machine-types';
import { ResultIcon } from './result-icon';
import { ResultAvatar } from '../../../common/result-avatar';
import { type ResultRendererArgs } from '../../product-router';
import { defaultResultRenderer } from '../../../extensible/search-result/default-result-renderer';

/**
 * This function renders a component search result using the default component offered
 * by the search platform. Specifically, the SearchResultContent is used.
 *
 * We override this to use different size icons for pre query and post query states
 */
export const componentResultRenderer = (args: ResultRendererArgs) => {
	const { productState, searchResult } = args;

	const iconSize = productState === ProductStates.PreQuerySuccess ? 'small' : 'medium';
	const iconType = searchResult.icon?.toString() ?? '';

	return defaultResultRenderer({
		...args,
		searchResult: {
			...searchResult,
			icon: <ResultIcon type={iconType} size={iconSize} />,
		},
	});
};

/**
 * This function renders a people or team search result using the default component offered
 * by the search platform. Specifically, the SearchResultContent is used.
 *
 * We override this to use different size icons for pre query and post query states
 */
export const peopleAndTeamsResultRenderer = (args: ResultRendererArgs) => {
	const { productState, searchResult } = args;

	const iconSize = productState === ProductStates.PreQuerySuccess ? 'xsmall' : 'small';

	return defaultResultRenderer({
		...args,
		searchResult: {
			...searchResult,
			icon: (
				<ResultAvatar
					borderColor="transparent"
					src={searchResult.iconUrl}
					appearance="square"
					size={iconSize}
					testId="result-avatar"
				/>
			),
		},
	});
};
