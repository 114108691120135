import React, { type RefObject, useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import { isSearchConfluenceSpace } from '@atlassian/search-client';

import { ApplicationModes } from '../../../common/constants/application-modes';
import { PrimaryProductKeys } from '../../../common/constants/products';
import { Heading } from '../../../common/ui/quick-find/heading';
import { OAuthContainer } from '../../../common/utils/oauth-container';
import { onSearchDialogDismissed } from '../../../common/utils/quick-find/events/search-dialog-dismissed';
import { onSearchDialogShown } from '../../../common/utils/quick-find/events/search-dialog-shown';
import {
	isPostQueryLoadingOrResults,
	isPostQueryState,
	isPreQueryState,
	State,
} from '../../../common/utils/quick-find/state';
import ShowIfEligible from '../../../controllers/application-modes';
import { ErrorBoundary } from '../../../controllers/error-boundary';
import { useOAuthHandlers } from '../../../controllers/oauth';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../controllers/quick-find/analytics';
import type { QuickFindContent } from '../../../controllers/quick-find/fetch-results/types';
import {
	useQuickFindFooterOnClick,
	useQuickFindResultOnClick,
	useQuickFindResultOnContextMenu,
	useQuickFindResultOnHighlighted,
} from '../../../controllers/quick-find/utils';
import {
	useBootstrap,
	useIsHello,
	usePrimaryProduct,
	useSearchActions,
} from '../../../controllers/store';
import {
	useBootstrapActions,
	useQuickFindNewFeaturesExperimentEnabled,
} from '../../../controllers/store/bootstrap';

import { AssetsUnavailableBanner } from './banners/assets';
import { RovoTipBanner } from './banners/rovo';
import { Divider } from './divider';
import { ErrorScreen } from './error-screen';
import { FeedbackFooter } from './feedback-footer';
import { JiraSingleSiteSearch } from './jira/jira-single-site-search';
import { LandingScreen } from './landing-screen';
import { messages } from './messages';
import { NoResultsScreen } from './no-results-screen';
import { PeopleAndTeams } from './people-and-teams';
import { ProductEntity } from './product-entity';
import { ProductFilterHeader } from './product-filter-header';
import { useQueryFilterResults } from './query-filters';
import { RecentActivity } from './recent-activity';
import { RecentQuery } from './recent-query';
import { SearchResultSkeleton } from './result-skeleton';
import { SearchPageLink } from './search-page-link';
import { SearchPagePrimaryProductLink } from './search-page-primary-product-link';
import {
	isErrorItem,
	isHeadingItem,
	isLandingItem,
	isNoResultsItem,
	isPeopleAndTeamsItem,
	isRecentActivityItem,
	isRecentQueryItem,
	isSearchResultItem,
	isSkeletonItem,
	type Item,
} from './types';

const searchDialogStyles = xcss({
	display: 'flex',
	position: 'absolute',
	flexDirection: 'column',
	backgroundColor: 'elevation.surface.overlay',
	borderRadius: '4px',
	boxShadow: 'elevation.shadow.overlay',
	marginTop: 'space.100',
	width: '100%',
});

const searchResultsSectionStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	paddingBlockStart: 'space.100',
	paddingBlockEnd: 'space.100',
	gap: 'space.025',
	maxHeight: 'calc(100vh - 220px)',
	overflowY: 'auto',
});

type SearchDialogProps = {
	dialogId: string;
	dialogRef: RefObject<HTMLDivElement>;
	inputRef?: RefObject<HTMLInputElement>;
	content: QuickFindContent;
	localQuery: string;
	setLocalQuery: React.Dispatch<React.SetStateAction<string>>;
};

export const SearchDialog = ({
	dialogId,
	dialogRef,
	content,
	localQuery,
	setLocalQuery,
	inputRef,
}: SearchDialogProps) => {
	const intl = useIntl();

	const [primaryProduct] = usePrimaryProduct();
	const { retrySearch } = useSearchActions();

	const [{ thirdPartyConfigs, products: availableProducts }] = useBootstrap();
	const [isHello] = useIsHello();

	const allItems = useContent(content);

	useResetSessionAnalytics();
	useAnalyticsEvents();

	const resultOnClick = useQuickFindResultOnClick();
	const resultOnContextMenu = useQuickFindResultOnContextMenu();
	const resultOnHighlighted = useQuickFindResultOnHighlighted();
	const footerOnClick = useQuickFindFooterOnClick();

	const [quickFindExperimentEnabled] = useQuickFindNewFeaturesExperimentEnabled();

	const queryFiltersBody = useQueryFilterResults({ localQuery, setLocalQuery, inputRef });

	if (!content) {
		return null;
	}

	const { state } = content;

	const body = [];

	for (const content of allItems) {
		if (isErrorItem(content)) {
			body.push(<ErrorScreen key={content.id} onRetry={retrySearch} />);
		}

		if (isLandingItem(content)) {
			body.push(<LandingScreen key={content.id} />);
		}

		if (isNoResultsItem(content)) {
			body.push(<NoResultsScreen key={content.id} onRetry={retrySearch} />);
		}

		if (isHeadingItem(content)) {
			body.push(
				<Heading
					key={content.id}
					id={content.id}
					text={content.data}
					includeInAnalytics={content.includeInAnalytics}
				/>,
			);
		}

		if (isSkeletonItem(content)) {
			body.push(<SearchResultSkeleton key={content.id} />);
		}

		if (isRecentQueryItem(content)) {
			body.push(<RecentQuery key={content.id} result={content.data} state={state} />);
		}

		if (isRecentActivityItem(content)) {
			body.push(
				<RecentActivity
					key={content.id}
					result={content.data}
					state={state}
					onClick={resultOnClick}
					onContextMenu={resultOnContextMenu}
					onHighlighted={resultOnHighlighted}
				/>,
			);
		}

		if (isSearchResultItem(content)) {
			body.push(
				<ProductEntity
					key={content.id}
					result={content.data}
					state={state}
					onClick={resultOnClick}
					onContextMenu={resultOnContextMenu}
					onHighlighted={resultOnHighlighted}
				/>,
			);
		}

		if (isPeopleAndTeamsItem(content)) {
			body.push(
				<PeopleAndTeams
					key={content.id}
					result={content.data}
					state={state}
					onClick={resultOnClick}
					onContextMenu={resultOnContextMenu}
					onHighlighted={resultOnHighlighted}
				/>,
			);
		}
	}

	const footer = (() => {
		// Post-query state with Confluence or Focus
		if (
			(primaryProduct === PrimaryProductKeys.Confluence ||
				primaryProduct === PrimaryProductKeys.Focus) &&
			isPostQueryState(state)
		) {
			return (
				<>
					<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
						<Divider />
						<SearchPageLink state={state} onClick={footerOnClick} />
					</ShowIfEligible>
					{isPostQueryLoadingOrResults(state) && (
						<>
							<Divider />
							<SearchPagePrimaryProductLink state={state} onClick={footerOnClick} />
						</>
					)}
					<Divider />
					<FeedbackFooter />
				</>
			);
		}

		if (primaryProduct === PrimaryProductKeys.Jira) {
			return (
				<>
					<Divider />
					<SearchPageLink state={state} onClick={footerOnClick} />
					<JiraSingleSiteSearch />
					<FeedbackFooter />
				</>
			);
		}

		// Pre-query footer
		return (
			<>
				<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
					<Divider />
					<SearchPageLink state={state} onClick={footerOnClick} />
				</ShowIfEligible>
				<ShowIfEligible requiredMode={ApplicationModes.Unified}>
					<Divider />
					<SearchPagePrimaryProductLink state={state} onClick={footerOnClick} />
				</ShowIfEligible>
				<Divider />
				<FeedbackFooter />
			</>
		);
	})();

	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	const showFilterHeader =
		isPostQueryLoadingOrResults(state) &&
		(fg('quick_find_3p_discoverability') || quickFindExperimentEnabled);

	return (
		<Box
			xcss={searchDialogStyles}
			aria-label={intl.formatMessage(messages.searchDialogAriaLabel)}
			testId={'search-dialog'}
			ref={dialogRef}
			id={dialogId}
		>
			{queryFiltersBody ? (
				<Box xcss={searchResultsSectionStyles}>{queryFiltersBody}</Box>
			) : (
				<>
					<Box xcss={searchResultsSectionStyles}>
						{primaryProduct === PrimaryProductKeys.Assets && <AssetsUnavailableBanner />}
						<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
							{/* eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix */}
							{isPreQueryState(state) &&
								(fg('quick_find_3p_discoverability') || quickFindExperimentEnabled) && (
									<ErrorBoundary>
										<OAuthContainer
											isOnSearchDialog
											thirdPartyConfigs={thirdPartyConfigs}
											availableProducts={availableProducts}
											isHello={isHello}
											useOAuthHandlers={useOAuthHandlers}
										/>
									</ErrorBoundary>
								)}
							{(isPreQueryState(state) || isPostQueryLoadingOrResults(state)) && <RovoTipBanner />}
							{showFilterHeader && <ProductFilterHeader availableProducts={availableProducts} />}
						</ShowIfEligible>
						{body}
					</Box>
					{footer}
				</>
			)}
		</Box>
	);
};

const useContent = (content: QuickFindContent) => {
	const intl = useIntl();

	return useMemo(() => {
		if (!content) {
			return [];
		}

		const { state } = content;

		const items: Item[] = [];

		const recentsHeadingItem = {
			id: 'recent-activities',
			type: 'heading' as const,
			data: intl.formatMessage(messages.recentsHeading),
		};

		const searchResultsHeadingItem = {
			id: 'search-results',
			type: 'heading' as const,
			data: intl.formatMessage(messages.searchResultsHeading),
		};

		if (state === State.PREQUERY_ERROR) {
			items.push({ id: 'error', type: 'error' });
		}

		if (state === State.PREQUERY_LOADING) {
			items.push(...content.recentQueries.slice(0, 3));
			items.push(recentsHeadingItem);
			for (let i = 0; i < 7; i++) {
				items.push({ id: `skeleton-${i}`, type: 'skeleton' });
			}
		}

		if (state === State.PREQUERY_NO_RESULTS) {
			items.push({ id: 'landing', type: 'landing' });
		}

		if (state === State.PREQUERY_RESULTS) {
			items.push(...content.recentQueries.slice(0, 3));
			if (content.recentActivities.length > 0) {
				items.push(recentsHeadingItem);
				items.push(...content.recentActivities.slice(0, 7));
			}
		}

		if (state === State.POSTQUERY_ERROR) {
			items.push({ id: 'error', type: 'error' });
		}

		if (state === State.POSTQUERY_LOADING) {
			if (content.recentActivities.length > 0) {
				items.push(recentsHeadingItem);
				items.push(...content.recentActivities.slice(0, 5));
			}

			items.push({ ...searchResultsHeadingItem, includeInAnalytics: false });
			for (let i = 0; i < 5; i++) {
				items.push({ id: `skeleton-${i}`, type: 'skeleton' });
			}
		}

		if (state === State.POSTQUERY_NO_RESULTS) {
			items.push({ id: 'no-results', type: 'no-results' });
		}

		if (state === State.POSTQUERY_RESULTS) {
			const dedupedSearchResults = content.searchResults.filter((searchResult) => {
				const isDuplicate = content.recentActivities
					.slice(0, 5)
					.some((recentlyViewedResult) => recentlyViewedResult.id === searchResult.id);
				return searchResult.id && !isDuplicate;
			});
			const filteredSearchResults = dedupedSearchResults.filter(
				(result) => !isSearchConfluenceSpace(result.data),
			);

			const confluenceSpaceResult = dedupedSearchResults.find((result) =>
				isSearchConfluenceSpace(result.data),
			);

			if (content.recentActivities.length > 0) {
				items.push(recentsHeadingItem);
				items.push(...content.recentActivities.slice(0, 5));
			}

			const showSearchResultsSection = Boolean(
				dedupedSearchResults.length + content.peopleAndTeams.length + content.recentQueries.length,
			);

			if (showSearchResultsSection) {
				items.push(searchResultsHeadingItem);
				items.push(...filteredSearchResults.slice(0, confluenceSpaceResult ? 5 : 6));
				confluenceSpaceResult && items.push(confluenceSpaceResult);
				items.push(...content.peopleAndTeams.slice(0, 2));
				items.push(...content.recentQueries.slice(0, 1));
			}
		}

		return items;
	}, [content, intl]);
};

const useAnalyticsEvents = () => {
	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	useEffect(() => {
		fireAnalyticsEvent(
			onSearchDialogShown({ attributes: commonAttributes, nonPrivacySafeAttributes }),
		);

		return () => {
			fireAnalyticsEvent(
				onSearchDialogDismissed({ attributes: commonAttributes, nonPrivacySafeAttributes }),
			);
		};
		// Only want these events to fire when the dialog opens and closes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

const useResetSessionAnalytics = () => {
	const { refreshSearchSessionId } = useBootstrapActions();
	const { resetQueryVersion } = useSearchActions();

	useEffect(() => {
		refreshSearchSessionId();
		resetQueryVersion();
	}, [refreshSearchSessionId, resetQueryVersion]);
};
