import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	assets: {
		id: 'search_dialog_assets_product_name',
		defaultMessage: 'Assets',
		description: 'Name of the product',
	},
	assetsUnavailableInfo: {
		id: 'search_dialog_assets_unavailable_info',
		defaultMessage: '{assets} will be available here soon. For now, try {assetsSearch}.',
		description:
			'Informs that Assets is currently unavailable (added as placeholder), with a button provided go to "assets search"',
	},
	assetsSearch: {
		id: 'search_dialog_assets_search',
		defaultMessage: 'Assets search',
		description: 'Refers to the search experience provided by the product named Assets',
	},
});
